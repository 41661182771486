import { gql } from '@apollo/client/core';

export const processFileMutation = gql`
  mutation processFile($groupId: ID!, $files: [Upload!]) {
    processFile(groupId: $groupId, files: $files) {
      id
      name
      path
    }
  }
`;

export const uploadFileUrlMutation = gql`
  mutation uploadFileUrl($groupId: ID!) {
    uploadFileUrl(groupId: $groupId) {
      id
      path
      url
    }
  }
`;

export const uploadFileIsDoneMutation = gql`
  mutation uploadFileIsDone(
    $groupId: ID!
    $id: ID!
    $name: String!
    $projectSourceDir: String
    $projectId: String
  ) {
    uploadFileIsDone(
      groupId: $groupId
      id: $id
      name: $name
      projectSourceDir: $projectSourceDir
      projectId: $projectId
    ) {
      id
      name
      path
    }
  }
`;

export const importMetaSettings = gql`
  mutation importMetaSettings(
    $groupId: ID!
    $files: [Upload!]
    $settingType: SettingType
  ) {
    importMetaSettings(
      groupId: $groupId
      files: $files
      settingType: $settingType
    )
  }
`;

export const updateMetaSettingsItem = gql`
  mutation updateMetaSettingsItem(
    $groupId: ID!
    $settingType: SettingType
    $key: String
    $item: JSON
  ) {
    updateMetaSettingsItem(
      groupId: $groupId
      settingType: $settingType
      key: $key
      item: $item
    )
  }
`;

const FRAGMENT_FILE_ON_FILE = gql`
  fragment FileOnFile on File {
    id
    name
    period
    useFile
    source_type
    type
    created
    hasError
    errorMessage
    errorCodes
    status {
      timestamp
      status
    }
    projects {
      projectId
      projectSourceDir
    }
  }
`;

export const getHistoricFilesQuery = gql`
  ${FRAGMENT_FILE_ON_FILE}
  query getHistoricFiles($groupId: ID!) {
    files(groupId: $groupId) {
      ...FileOnFile
    }
  }
`;

export const getInputFilesQuery = gql`
  query getInputFilesQuery($groupId: ID!, $from: String, $to: String) {
    files(groupId: $groupId, type: INPUT, range: { from: $from, to: $to }) {
      name
      size
      datasets
      period
      sortKey
    }
  }
`;

export const deleteReportMutation = gql`
  mutation deleteReport($groupId: ID!, $sortKey: String) {
    deleteReport(groupId: $groupId, sortKey: $sortKey)
  }
`;

export const downloadFile = gql`
  query downloadFile($groupId: ID!, $sortKey: String, $filename: String) {
    downloadFile(groupId: $groupId, sortKey: $sortKey, filename: $filename) {
      name
      file
    }
  }
`;

export const deleteFileMutation = gql`
  mutation deleteFile($groupId: ID!, $id: String!) {
    deleteFile(groupId: $groupId, id: $id) {
      id
    }
  }
`;

export const UPDATE_USE_FILE_MUTATION = gql`
  mutation updateUseFile($groupId: ID!, $id: String!, $useFile: Boolean!) {
    updateUseFile(groupId: $groupId, id: $id, useFile: $useFile) {
      id
      useFile
    }
  }
`;

export const GET_FILE_QUERY = gql`
  ${FRAGMENT_FILE_ON_FILE}
  query file($groupId: ID!, $id: String!) {
    file(groupId: $groupId, id: $id) {
      ...FileOnFile
    }
  }
`;

export const ANNOTATE_PROJECT_FILE_MUTATION = gql`
  ${FRAGMENT_FILE_ON_FILE}
  mutation annotateProjectFile(
    $groupId: ID!
    $id: ID!
    $projectId: String!
    $projectSourceDir: String!
  ) {
    annotateProjectFile(
      groupId: $groupId
      id: $id
      projectId: $projectId
      projectSourceDir: $projectSourceDir
    ) {
      ...FileOnFile
    }
  }
`;

export const REMOVE_PROJECT_FILE_ANNAOTATION_MUTATION = gql`
  ${FRAGMENT_FILE_ON_FILE}
  mutation removeProjectFileAnnotation(
    $groupId: ID!
    $id: ID!
    $projectId: String!
  ) {
    removeProjectFileAnnotation(
      groupId: $groupId
      id: $id
      projectId: $projectId
    ) {
      ...FileOnFile
    }
  }
`;

export const DOWNLOAD_FILE_QUERY = gql`
  query downloadStoredFile($groupId: ID!, $id: String!) {
    downloadStoredFile(groupId: $groupId, id: $id) {
      name
      file
    }
  }
`;
