import { Box, keyframes } from '@mui/material';
import { FC } from 'react';
import fasticon from '../../../assets/customer-logos/fasticon_logo.png';
import norrkoping from '../../../assets/customer-logos/norrkoping_logo.png';
import nykoping from '../../../assets/customer-logos/nykoping_logotyp.png';
import parksresort from '../../../assets/customer-logos/parks-and-resorts-logo.png';
import ssb from '../../../assets/customer-logos/ssb_logo_new.png';
import iterum from '../../../assets/customer-logos/iterum_vit.svg';
import fantastiska from '../../../assets/customer-logos/fantastika_logo.png';
import thoren from '../../../assets/customer-logos/thorengruppen_logo.png';
import anticimex from '../../../assets/customer-logos/anticimex_logo.png';
import sos from '../../../assets/customer-logos/sos_alarm_logo.png';

const logos = [nykoping, parksresort, thoren, anticimex];
const largeLogos = [fasticon];
const smallLogos = [norrkoping, ssb, iterum];
const xSmallLogos = [sos, fantastiska];

const marquee = keyframes`
    from {
      transform: translatex(0%)
    }

    to {
      transform: translatex(-100%)
    }
`;

const CustomerLogs: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        userSelect: 'none',
        gap: 1,
        mb: 4,
        maskImage:
          'linear-gradient(to right, hsl(0 0% 0% / 0),hsl(0 0% 0% / 1) 20%,hsl(0 0% 0% / 1) 80%,hsl(0 0% 0% / 0))',
        '&:hover': {
          '& > div': {
            animationPlayState: 'paused',
          },
        },
      }}
    >
      {[1, 2].map((i) => (
        <Box
          key={i}
          sx={{
            flexShrink: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: 2,
            minWidth: 1,
            animation: `${marquee} 32s linear infinite`,
          }}
        >
          {largeLogos.map((logo, index) => (
            <Box
              height={24}
              component="img"
              key={index}
              sx={{
                display: 'inline-block',
                mx: 2,
                height: 24,
                opacity: 0.8,
                filter: 'brightness(0) invert(1)',
              }}
              src={logo}
            />
          ))}

          {smallLogos.map((logo, index) => (
            <Box
              height={40}
              component="img"
              key={index}
              sx={{
                display: 'inline-block',
                mx: 2,
                height: 40,
                opacity: 0.8,
                filter: 'brightness(0) invert(1)',
              }}
              src={logo}
            />
          ))}
          {xSmallLogos.map((logo, index) => (
            <Box
              height={50}
              component="img"
              key={index}
              sx={{
                display: 'inline-block',
                mx: 2,
                height: 50,
                opacity: 0.8,
                filter: 'brightness(0) invert(1)',
              }}
              src={logo}
            />
          ))}

          {logos.map((logo, index) => (
            <Box
              height={32}
              component="img"
              key={index}
              sx={{
                display: 'inline-block',
                mx: 2,
                height: 32,
                opacity: 0.8,
                filter: 'brightness(0) invert(1)',
              }}
              src={logo}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default CustomerLogs;
