import { Alert, AlertTitle, AlertProps } from '@mui/material';
import { FC } from 'react';

export interface BannerProps extends AlertProps {
  message?: string;
}

const Banner: FC<BannerProps> = ({
  title,
  message,
  children,
  sx,
  ...restProps
}) => {
  return (
    <Alert
      sx={[
        {
          borderRadius: '99em',
          py: 0.75,
          px: 3,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...restProps}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  );
};

export default Banner;
