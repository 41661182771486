import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, useRef, useState } from 'react';
import { IoPlayOutline } from 'react-icons/io5';
import { muiDarkTheme } from '../../../theme/darkTheme';
import Card from '../../../ui/Card/Card';
import { hexToRgba } from '../../../utils/hexToRgba';

declare global {
  interface Window {
    gtag: any;
  }
}

export interface GatsbyVideoSectionProps {
  title: string;
  subtitle: string;
  video?: string;
  category?: string;
  index: number;
}

const GatsbyVideoSection: FC<GatsbyVideoSectionProps> = ({
  video,
  title,
  subtitle,
  index,
  category,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const ref = useRef<HTMLVideoElement>(null);
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.only('xs'));

  const handlePlay = () => {
    if (ref && ref.current) {
      ref.current.controls = true;
      setIsPlaying(true);
      ref.current.play();
      setHasPlayed(true);

      if (!hasPlayed) {
        window.gtag('event', 'PROMO_PLAY');
      }
    }
  };

  const handlePause = () => {
    if (ref && ref.current) {
      ref.current.controls = false;
    }
    setIsPlaying(false);
  };

  const handleEnded = () => {
    if (ref && ref.current) {
      ref.current.currentTime = 0;
      ref.current.controls = false;
      setHasPlayed(false);
      window.gtag('event', 'PROMO_ENDED');
    }
  };

  return (
    <Card
      id="viedo"
      sx={{
        bgcolor: '#fadae6',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        mt: index === 0 || match ? 'unset' : 5,
        mb: 5,
        p: 5,
      }}
      padding={0}
    >
      <Container maxWidth="sm">
        {category && (
          <Typography paragraph variant="subtitle1">
            {category}
          </Typography>
        )}
        <Typography variant="h4" sx={{ color: '#320047' }}>
          {title}
        </Typography>
        <Box marginTop="1rem">
          <Typography variant="h5">{subtitle}</Typography>
        </Box>
        <Box
          position="relative"
          sx={{ mt: 5, borderRadius: 10, overflow: 'hidden' }}
        >
          <video
            ref={ref}
            width="100%"
            height="100%"
            onPause={handlePause}
            onEnded={handleEnded}
          >
            <source src={video} type="video/mp4" />
            Din webbläsare stöder inte video.
          </video>
          {!isPlaying && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                borderColor: 'secondary.main',
                color: 'secondary.main',
                '&:hover': {
                  cursor: 'pointer',
                  borderColor: 'secondary.dark',
                  color: 'secondary.dark',
                },
                backgroundColor: hexToRgba(
                  muiDarkTheme.palette.background.default,
                  0.3
                ),
              }}
              onClick={handlePlay}
            >
              <Box
                sx={{
                  height: theme.spacing(10),
                  width: theme.spacing(10),
                  borderRadius: 10,
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderColor: 'inherit',
                  color: 'inherit',
                }}
              >
                <IoPlayOutline
                  style={{
                    fontSize: '40px',
                    color: 'inherit',
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Card>
  );
};

export default GatsbyVideoSection;
