import { gql } from '@apollo/client';

export const DELETE_PROJECT_MUTATION = gql`
  mutation deleteProject($id: ID!, $groupId: ID!) {
    deleteProject(id: $id, groupId: $groupId) {
      success
      message
    }
  }
`;

export const PROJECT_FRAGMENT = gql`
  fragment ProjectFragment on Project {
    id
    name
    description
    variant
    sources {
      type
      key
      fromSource
      toSource
    }
    startDate
    endDate
    createdBy {
      id
    }
    updatedBy {
      id
    }
  }
`;

export const PROJECT_QUERY = gql`
  ${PROJECT_FRAGMENT}
  query Project($id: ID!, $groupId: ID!) {
    project(id: $id, groupId: $groupId) {
      ...ProjectFragment
    }
  }
`;

export const PROJECTS_QUERY = gql`
  ${PROJECT_FRAGMENT}
  query Projects($groupId: ID!) {
    projects(groupId: $groupId) {
      ...ProjectFragment
    }
  }
`;

export const CREATE_PROJECT_MUTATION = gql`
  ${PROJECT_FRAGMENT}
  mutation createProject($groupId: ID!, $project: ProjectInput!) {
    createProject(groupId: $groupId, project: $project) {
      ...ProjectFragment
    }
  }
`;

export const UPDATE_PROJECT_MUTATION = gql`
  ${PROJECT_FRAGMENT}
  mutation updateProject($groupId: ID!, $id: ID!, $project: ProjectInput!) {
    updateProject(groupId: $groupId, id: $id, project: $project) {
      ...ProjectFragment
    }
  }
`;
