import { useEffect, useMemo, useState } from 'react';
import { ChartDataSet } from '../chart/chart.model';
import { formatNumber } from './helpers';

const milion = Math.pow(10, 6);

export const yAxisFormater = (value: number, index: number): string => {
  if (value < 10 && value - Math.floor(value) !== 0) {
    return formatNumber(value, 1);
  }

  if (value > milion) {
    return formatNumber(Math.round(value / 1000), 0, ' k');
  }

  return formatNumber(value, 0);
};

export const useCheckBoxDataset = (dataDataset: any, checkBoxes: any) => {
  const dataset = useMemo<ChartDataSet>(() => {
    if (dataDataset && checkBoxes) {
      const dataset = dataDataset.map((data: any) => {
        const setZero = Object.entries(checkBoxes).reduce(
          (state, [key, checked]: any) => {
            if (!checked) state[key] = 0;

            return state;
          },
          {} as any
        );

        return {
          ...data,
          ...setZero,
        };
      });

      return dataset;
    }
    return [];
  }, [dataDataset, checkBoxes]);

  return dataset;
};

export const useCheckBoxes = (dataProperties: any) => {
  const theState = useState();
  useEffect(() => {
    const prep = dataProperties?.reduce((state: any, prop: string) => {
      state[prop] = true;

      return state;
    }, {} as any);

    theState[1](prep);
  }, [dataProperties]);

  return theState;
};
