import { gql } from "@apollo/client";

export const SETTING = gql`
query setting($groupId: ID!, $id: ID!) {
  setting(groupId: $groupId, id: $id){
    id
    name
    value
    schema
  }
}
`;


export const LIST_SETTINGS = gql`
query listSettings($groupId: ID!) {
  listSettings(groupId: $groupId) 
}
`;


export const UPDATE_SETTING = gql`
mutation updateSetting($groupId: ID!, $id: ID!, $value:JSON!) {
  updateSetting(groupId: $groupId, id: $id, value: $value) {
    id
    name
    value
    schema
  }
}
`;

