import { useTheme } from '@mui/material';
import React, { FC } from 'react';
import {
  ResponsiveContainer,
  RadialBarChart as ReRadialBarChart,
  RadialBar,
  Tooltip,
} from 'recharts';
import CustomTooltip from '../Tooltip/CustomTooltip';

interface RadialBarChartProps {
  data: any[];
}

const RadialBarChart: FC<RadialBarChartProps> = ({ data }) => {
  const { palette } = useTheme();
  return (
    <ResponsiveContainer height="100%" width="100%">
      <ReRadialBarChart
        cx="50%"
        cy="50%"
        innerRadius="30%"
        outerRadius="100%"
        barSize={20}
        data={data}
        // startAngle={0}
        // endAngle={270}
      >
        <RadialBar
          // label={{ position: 'end', fill: '#000' }}
          // label={(e) => {
          //   console.log(e);
          //   return <div />;
          // }}

          background={{ fill: palette.other.emptyChartFill }}
          dataKey="andel"
          width={20}
        />
        <Tooltip
          content={({ label, ...rest }) => {
            const newLabel = data.find((d) => d.originKey === label + 1)?.key;
            return <CustomTooltip {...rest} label={newLabel} />;
          }}
        />
      </ReRadialBarChart>
    </ResponsiveContainer>
  );
};

export default RadialBarChart;
