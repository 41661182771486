import { gql } from '@apollo/client';

export const RECINCILIATION_SETTINGS_FRAGMENT = gql`
  fragment SettingsFragment on ReconciliationSettings {
    id
    to_pay {
      disabled
      filters {
        field
        values
      }
    }
    tax {
      disabled
      filters {
        field
        values
      }
    }
    soc {
      disabled
      filters {
        field
        values
      }
    }
    cach_compensation {
      disabled
      filters {
        field
        values
      }
    }
    sick_pay_cost {
      disabled
      filters {
        field
        values
      }
    }
    accouning_soc_default {
      disabled
      filters {
        field
        values
      }
    }
    accouning_soc_special {
      disabled
      filters {
        field
        values
      }
    }
    accouning_accrued_default {
      disabled
      filters {
        field
        values
      }
    }
    accouning_accrued_special {
      disabled
      filters {
        field
        values
      }
    }
    accouning_fora {
      disabled
      filters {
        field
        values
      }
    }
    accrued_salary_tot {
      disabled
      filters {
        field
        values
      }
    }
    accrued_salary_soc {
      disabled
      filters {
        field
        values
      }
    }
    allowance {
      disabled
      filters {
        field
        values
      }
    }
  }
`;

export const RECINCILIATION_SETTINGS_QUERY = gql`
  ${RECINCILIATION_SETTINGS_FRAGMENT}
  query reconciliationSettings($groupId: ID!) {
    reconciliationSettings(groupId: $groupId) {
      ...SettingsFragment
    }
  }
`;
export const UPDATE_RECINCILIATION_SETTINGS = gql`
  ${RECINCILIATION_SETTINGS_FRAGMENT}
  mutation updateReconciliationSettings(
    $groupId: ID!
    $input: ReconciliationSettingsInput!
  ) {
    updateReconciliationSettings(groupId: $groupId, input: $input) {
      ...SettingsFragment
    }
  }
`;
