import { IconButton, Typography, Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

export interface DialogTitleProps {
  id?: string;
  children: ReactNode;
  onClose?: () => void;
}

const DialogTitle: FC<DialogTitleProps> = ({ children, onClose, ...other }) => {
  return (
    <Box sx={{ m: 0, p: 2 }} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          size="large"
          onClick={onClose}
          sx={({ palette, spacing }) => ({
            position: 'absolute',
            top: 8,
            right: 8,
            color: palette.grey[500],
          })}
        >
          <IoCloseOutline />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default DialogTitle;
