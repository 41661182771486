import { gql } from '@apollo/client/core';

export const getSurveillance = gql`
query surveillance($groupId: ID!, $period:String) {
  surveillance(groupId: $groupId) {
    id
    paycodes
    description
    category
    default
    rules{
      id
      name
      description
      active
      checkType
      status(period:$period){
        id  
        period
        count
        processing
        logs{
          id
          created
          status
          message
        }
      }
      selection(period:$period){
        id
        done
        next
        employee{
          company_registration_number
          employment_nummber
        }
      }
    }
  }
}
`;

export const updateRule = gql`
  mutation updateRule($groupId: ID!, $surveillanceId:ID!, $id:ID!, $period:String, $active:Boolean) {
    rule(groupId: $groupId, surveillanceId: $surveillanceId, id: $id, period: $period, active: $active) {
      id
      active  
      checkType
      status(period:$period){
        id  
        period
        count
        processing
        logs{
          id
          created
          status
          message
        }
      }    
      selection(period:$period){
        id
        done
        next
        employee{
          company_registration_number
          employment_nummber
        }
      }
    }
  }
`;

export const createSurveillance = gql`
  mutation createSurveillance($groupId: ID!, $paycode: [String]!, $input: SurveillanceInput, $asDefault: Boolean) {
    surveillance(groupId: $groupId, paycode: $paycode, input: $input, asDefault: $asDefault) {
      id
      paycodes
      description
      category
      default
      rules{
        id
        name
        description
        active
        checkType
        status{
          id
          period
          count
        }
        selection{
          id
          done
          next
          employee{
            company_registration_number
            employment_nummber
          }
        }
      }
    }
  }
`;

export const updateSurveillance = gql`
  mutation updateSurveillance($groupId: ID!, $id: ID!, $description:String, $defaultOwner:ID) {
    updateSurveillance(groupId: $groupId, id: $id, description:$description, defaultOwner:$defaultOwner) {
      id
      paycodes
      description
      rules{
        id
        name
        description
        active
      }
    }
  }
`;

export const removeSurveillance = gql`
mutation deleteSurveillance($groupId: ID!, $id: ID!) {
  deleteSurveillance(groupId: $groupId, id: $id) 
}
`

export const updateSurveillanceItem = gql`
mutation updateSurveillanceItem($groupId:ID!, $surveillanceId:ID!, $ruleId:ID!, $period:String!, $id:ID!, $beforeId:ID, $done:Boolean) {
  updateSurveillanceItem(groupId: $groupId, surveillanceId: $surveillanceId, ruleId: $ruleId, period: $period, id: $id, beforeId: $beforeId, done: $done){
    id
    done
    next
    employee{
      company_registration_number
      employment_nummber
    }
  }
}
`

export const checkRules = gql`
mutation checkRules($groupId: ID!, $surveillanceId:ID!, $period:String!) {
  checkRules(groupId: $groupId, surveillanceId: $surveillanceId, period: $period) {
    success
    message
  }
}
`