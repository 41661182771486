import { gql } from '@apollo/client';

export const DELETE_INVOICE_MUTATION = gql`
  mutation deleteInvoice($id: ID!, $groupId: ID!) {
    deleteInvoice(id: $id, groupId: $groupId) {
      success
      message
    }
  }
`;

export const INVOICE_FRAGMENT = gql`
  fragment InvoiceFragment on Invoice {
    id
    customerNumber
    invoiceNumber
    invoiceDate
    invoicePeriodStart
    invoicePeriodEnd
    dueDate
    paymentDate
    status
    amount
    amountNet
    vat
    discount
    currency
    sentToBooking
    items {
      start
      end
      description
      amount
      amountNet
    }
    billing {
      isPrivate
      companyName
      orgNo
      email
      reference
      firstName
      lastName
      address
      postalCode
      city
    }
    message
    refoundInvoiceId
  }
`;

export const CREATE_INVOICES_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  mutation createInvoices($groupId: ID!) {
    createInvoices(groupId: $groupId) {
      ...InvoiceFragment
    }
  }
`;

export const CREATE_ALL_INVOICES_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  mutation createAllInvoices {
    createAllInvoices {
      ...InvoiceFragment
      group {
        id
      }
    }
  }
`;

export const CREATE_INVOICE_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  mutation createInvoice($invoice: InvoiceInput!, $groupId: ID!) {
    createInvoice(invoice: $invoice, groupId: $groupId) {
      ...InvoiceFragment
    }
  }
`;

export const UPDATE_INVOCIE_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  mutation updateInvoice($invoice: InvoiceInput!, $groupId: ID!) {
    updateInvoice(invoice: $invoice, groupId: $groupId) {
      ...InvoiceFragment
    }
  }
`;

export const UPDATE_INVOICE_STATUS_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  mutation updateInvoiceStatus(
    $id: ID!
    $status: InvoiceStatus!
    $groupId: ID!
  ) {
    updateInvoiceStatus(id: $id, status: $status, groupId: $groupId) {
      ...InvoiceFragment
    }
  }
`;

export const LIST_INVOICE_META_QUERY = gql`
  query listInvoiceMeta {
    listInvoiceMeta {
      id
      count
      date
      sentToBooking
    }
  }
`;

export const CREATE_BOOKING_INVOICE_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  mutation createBookingForInvoiceRun($id: ID!) {
    createBookingForInvoiceRun(id: $id) {
      ...InvoiceFragment
    }
  }
`;
