import { gql } from '@apollo/client/core';

export const PAYSLIPS_V2_QUERY = gql`
  query payslipsV2($groupId: ID!, $employeeId: ID!) {
    payslipsV2(groupId: $groupId, employeeId: $employeeId) {
      Meta {
        company_registration_number
        employment_nummber
        payment_date
        to_pay
      }

      PayslipTransactions {
        paycode
        from
        to
        quantity
        unit_price
        procent
        amount
        text
      }
      PayslipInfo {
        name
        value_a
        value_n
      }
    }
  }
`;

export const GET_PAYSLIP_QUERY = gql`
  query GetPayslipData(
    $groupId: ID!
    $employee: EmployeeInput!
    $type: StatsType = PAYSLIP
  ) {
    getPayslips(groupId: $groupId, employee: $employee, type: $type) {
      Meta {
        company_registration_number
        employment_nummber
        payment_date
        to_pay
      }

      PayslipTransactions {
        paycode
        from
        to
        quantity
        unit_price
        procent
        amount
        text
      }
      PayslipInfo {
        name
        value_a
        value_n
      }
    }
  }
`;

export const employeeSearch = gql`
  query Employees($groupId: ID!, $search: String) {
    employees(groupId: $groupId, search: $search) {
      id
      company_registration_number
      employment_nummber
    }
  }
`;

export const employeesSelection = gql`
  query EmployeesSelection(
    $groupId: ID!
    $type: StatsType
    $period: String!
    $paycodes: [String]
  ) {
    employeesSelection(
      groupId: $groupId
      type: $type
      period: $period
      paycodes: $paycodes
    ) {
      company_registration_number
      employment_nummber
    }
  }
`;
