import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';

import DialogTitle from '../../Dialog/DialogTitle';
import ContactForm from './ContactForm';
import { AnimMessageSent } from '../../Lottie';

interface Props {
  buttonProps?: ButtonProps;
}

const ContactDialog: FC<Props> = ({ buttonProps = {} }) => {
  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(false);

  const handleCloseWindow = () => {
    setOpen(false);
    setSent(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        {...buttonProps}
        onClick={() => setOpen(true)}
      >
        Kontakta oss
      </Button>
      <Dialog fullWidth open={open} onClose={handleCloseWindow}>
        <DialogTitle onClose={handleCloseWindow}>
          {!sent && 'Kontakta oss'}
        </DialogTitle>
        <DialogContent>
          {!sent && <ContactForm onAddInquiry={() => setSent(true)} />}
          {sent && (
            <Box
              display="flex"
              flexDirection="column"
              textAlign="center"
              alignItems="center"
              marginY="30px"
            >
              <AnimMessageSent />
              <Box marginY="8px">
                <Typography variant="h3">Tack för ditt meddelande</Typography>
              </Box>
              <Box marginY="8px">
                <Typography variant="h5">
                  Vi kommer kontakta dig så fort som möjligt.
                </Typography>
              </Box>
              <Box marginY="8px">
                <Button color="secondary" onClick={handleCloseWindow}>
                  Stäng fönstret
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContactDialog;
