import { FC, useState } from 'react';
import * as Yup from 'yup';
import { Form, FormInput, Input } from '../../Form';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Box, Button, MenuItem } from '@mui/material';
import { InquiryForm, useGatsby } from '@intly/core';

const validationSchema = Yup.object({
  firstName: Yup.string().required('Förnamn är obligatorisk'),
  lastName: Yup.string().required('Efternamn är obligatorisk'),
  email: Yup.string()
    .email('Felaktig E-post')
    .required('E-post är obligatorisk'),
  company: Yup.string().required('Företag är obligatorisk'),
  message: Yup.string().required('Meddelande är obligatorisk'),
});

export interface ContactFormProps {
  onAddInquiry?: () => void;
}

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  reason: 'CONTACT',
  message: '',
};

const ContactForm: FC<ContactFormProps> = ({ onAddInquiry }) => {
  const { addInquiry } = useGatsby();
  const [isValid, setIsValid] = useState(false);
  const [state, setState] = useState<InquiryForm>(defaultState);

  const handleAddInquiry = () => {
    setIsValid(false);
    addInquiry(state);
    onAddInquiry && onAddInquiry();
    setState(defaultState);
  };

  return (
    <Box
      sx={{
        maxWidth: 700,
        mb: 2,
        mx: {
          xs: 0,
          sm: 4,
        },
      }}
      maxWidth={700}
      mb={2}
    >
      <Form
        onFormStateChange={setIsValid}
        onChange={setState}
        defaultValues={defaultState}
        schema={validationSchema}
      >
        <Grid2 container spacing={2}>
          <Grid2 xs={12} sm={6}>
            <FormInput
              fullWidth
              size="medium"
              name="firstName"
              label="Förnamn *"
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <FormInput
              fullWidth
              size="medium"
              name="lastName"
              label="Efternamn *"
            />
          </Grid2>
          <Grid2 xs={12}>
            <FormInput fullWidth size="medium" name="email" label="E-post *" />
          </Grid2>
          <Grid2 xs={12}>
            <FormInput
              fullWidth
              size="medium"
              name="company"
              label="Företag *"
            />
          </Grid2>
          <Grid2 xs={12}>
            <FormInput
              select
              fullWidth
              size="medium"
              name="reason"
              label="Ärende"
            >
              <MenuItem value="CONTACT">Kontakt</MenuItem>
              <MenuItem value="DEMO">Demo av Intly</MenuItem>
              <MenuItem value="SUPPORT">Hjälp eller support</MenuItem>
              <MenuItem value="SUBSCRIBE">Prenumerera på nyhetsbrev</MenuItem>
            </FormInput>
          </Grid2>
          <Grid2 xs={12}>
            <FormInput
              fullWidth
              multiline
              rows={5}
              size="medium"
              name="message"
              label="Meddelande *"
            />
          </Grid2>
        </Grid2>
      </Form>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Button
          fullWidth
          size="large"
          color="secondary"
          disabled={!isValid}
          onClick={handleAddInquiry}
        >
          Skicka
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
