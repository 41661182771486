import { makeVar } from '@apollo/client/core';
import { Membership, User } from './user.model';
import { useReactiveVar } from '@apollo/client';

export const selectedMembership = makeVar<string>('');
export const selectedMembershipObject = makeVar<Membership | null>(null);
export const subscriptionFeatures = makeVar<Set<string>>(new Set());

type SettingsDialogVar =
  | { page: null; subpage?: null }
  | { page: 'USER'; subpage?: null }
  | {
      page: 'SUBSCRIPTIONS';
      subpage?: 'INVOICES' | 'MEMBERS' | 'MANAGE' | 'BILLING';
    }
  | { page: 'PAY_PULSE'; subpage?: null };

export const settingsDialogOpen = makeVar<SettingsDialogVar>({
  page: null,
  subpage: null,
});

export const isSuperAdmin = makeVar<boolean>(false);
export const isAdmin = makeVar<boolean>(false);

export const impersonateUser = makeVar<User | null>(null);

export const useIsSuperAdmin = (ignoreImpersonate?: boolean): boolean => {
  const superadmin = useReactiveVar(isSuperAdmin);
  const impersonate = useReactiveVar(impersonateUser);

  if (ignoreImpersonate) {
    return superadmin;
  }

  return superadmin && !impersonate;
};

export const useIsTenant = (): boolean => {
  const selected = useReactiveVar(selectedMembership);
  const selectedObject = useReactiveVar(selectedMembershipObject);

  return (
    selected === selectedObject?.group.id &&
    Boolean(selectedObject?.group.isTenant)
  );
};
