import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { muiLightTheme } from '@intly/design';
import { FC } from 'react';

const TopLayout: FC = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={muiLightTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  );
};

export default TopLayout;
