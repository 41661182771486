import { Group } from '../group/group.model';

export enum UserRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface User {
  id: string;
  externalId: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  membership: Membership[];
  showTour: { value: string };
}

export interface Membership {
  id: string;
  role: UserRole;
  user: User;
  memberAt: number;
  status: MembershipStatus;
  addedBy: User;
  group: Group;
}

export enum MembershipStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
}

export interface MeData {
  me: User;
}
