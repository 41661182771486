import { DateTime } from 'luxon';

export const getInitialsFromString = (text: string = '?'): string => {
  const words = text.split(' ');
  const wordArr = words.map((w) => w.substr(0, 1));
  return ((wordArr.shift() || '') + (wordArr.pop() || '')).toUpperCase();
};

export const getDistinctProps = (
  list: any[],
  prefilterd = false,
  includeOnly: string[] | undefined = undefined
): any => {
  const props = {} as any;
  for (let item of list) {
    for (let [prop, value] of Object.entries(item)) {
      const _prop: string = prop;
      const _value: any = value;
      if (includeOnly && !includeOnly.includes(_prop)) continue;
      if (!isNaN(_value) && prefilterd) continue;
      if (!props[_prop]) props[_prop] = new Set();
      props[_prop].add(_value ? _value.toString() : _value);
    }
  }

  const res = Object.entries(props).reduce((out: any, [prop, value]) => {
    out[prop] = Array.from(value as any);
    return out;
  }, {} as any);

  return res;
};

export const formatNumbersArray = (
  number: string | number,
  decimals: number = 2
): [Array<string>, string, number] => {
  let num = number;
  if (typeof num === 'string') {
    num = parseFloat((num as string).replace(',', '.'));
  }
  let temp = Math.round(Math.abs(num) * Math.pow(10, decimals)).toString();
  const _decimals = temp.substr(temp.length - decimals);

  let l = temp.length - decimals;
  let strAray: string[] = [];

  for (; l > 3; l -= 3) {
    strAray.unshift(temp.substring(l - 3, l));
  }
  strAray.unshift(temp.substring(0, l));

  return [strAray, _decimals, num];
};

export const formatNumbersStr = (
  number: string | number,
  decimals: number = 2
): string => {
  const formatArrat = formatNumbersArray(number, decimals);
  const [[head = 0, ...strAray], _decimals, num] = formatArrat;
  return `${num >= 0 ? '' : '-'}${head}${
    strAray.length > 0 ? ' ' : ''
  }${strAray.join(' ')}${_decimals ? ',' : ''}${_decimals.toString()}`;
};

export const dateFormat = (date: Date): string => {
  if (!date) return '';

  return date.toLocaleDateString('sv-SE');
};

export const formatDateFromString = (
  date: string,
  format = 'yyyy-MM-dd'
): string => {
  if (!date) return '';

  return DateTime.fromISO(date).toFormat(format);
};

export const formatNumber = (val: number, minDigits = 0, suffix = '') =>
  `${new Intl.NumberFormat('sv-SE', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: minDigits <= 2 ? 2 : minDigits,
  }).format(val)}${suffix}`;
