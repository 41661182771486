import { ServiceSubscription } from '@intly/core';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { PricingChange } from './Pricing.model';
import { PricingItem } from './PricingTiles';

const translateRole = {
  ADMIN: 'Administratör',
  USER: 'Användare',
  POWER_USER: 'Utökad\u00a0användare',
};

interface PricingExtraProps {
  subscriptions: ServiceSubscription[];
  selected?: PricingChange;
  onChange?: (change: PricingChange) => void;
  show?: boolean;
}

const PricingExtra: FC<PricingExtraProps> = ({
  subscriptions,
  show,
  selected,
  onChange,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(show || false);

  const onToggleExtra = (sub: ServiceSubscription) => {
    if (onChange && selected) {
      const extra = selected?.extra?.filter((e) => e.id !== sub.id) || [];

      onChange({
        ...selected,
        extra: selected?.extra?.some((e) => e.id === sub.id)
          ? extra || []
          : [...extra, sub],
      });
    }
  };

  return (
    <Box sx={{ mt: 4, px: 2, overflow: 'hidden' }}>
      <Typography variant="h6">
        Vi vill vara flexibla. Därför är vi inte så noggranna när vi räknar
        antalet anställda. Vi anväder medeltalet för senaste tre månaderna och
        avrundar alltid nedåt! 🙌 Beöver ni fler användare eller anställda kolla
        på våra tillägg.
      </Typography>
      {!show && (
        <Button sx={{ mt: 2 }} onClick={() => setCollapsed((curr) => !curr)}>
          Visa priser för tillägg
        </Button>
      )}
      <Collapse in={collapsed}>
        <Box sx={{ display: 'flex', my: 4, alignItems: 'center' }}>
          <Typography variant="h6" width={150}>
            Extra användare
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflowX: 'auto',
              scrollSnapType: 'x mandatory',
            }}
          >
            {subscriptions
              .filter(
                (s) =>
                  s.extra &&
                  s.propertys.some((p) => p.key === 'users' && Boolean(p.value))
              )
              .map((s) => {
                const selectedUser = selected?.extra?.some(
                  (sub) => sub.id === s.id
                );

                return (
                  <Box
                    key={s.id}
                    sx={{
                      scrollSnapAlign: 'center',
                      borderRadius: 10,
                      border: 'solid 1px',
                      borderColor: 'border',
                      ml: 2,
                      p: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h5">
                      {
                        translateRole[
                          s.propertys.find((p) => p.key === 'users')?.value ||
                            'USER'
                        ]
                      }
                    </Typography>

                    <PricingItem
                      cost={
                        (s.cost[selected?.billingOccurance || 'monthly']
                          ?.price ?? 0) /
                        (s.cost[selected?.billingOccurance || 'monthly']
                          ?.interval ?? 1)
                      }
                    />

                    {selected && (
                      <Button
                        sx={{ mt: 2 }}
                        color={selectedUser ? 'secondary' : 'primary'}
                        onClick={() => onToggleExtra(s)}
                      >
                        {selectedUser ? 'Ta bort' : 'Lägg till'}
                      </Button>
                    )}
                  </Box>
                );
              })}
          </Box>
        </Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Vi vill växa med dig 🚀. Det är enkelt att fylla på med fler anställda
          vid behov.
        </Typography>
        <Box sx={{ display: 'flex', mb: 4, alignItems: 'center' }}>
          <Typography variant="h6" width={150}>
            Extra anställda
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflowX: 'auto',
              scrollSnapType: 'x mandatory',
            }}
          >
            {subscriptions
              .filter(
                (s) =>
                  s.extra &&
                  s.propertys.some(
                    (p) => p.key === 'employees' && Boolean(p.value)
                  )
              )
              .map((s) => {
                const selectedEmp = selected?.extra?.some(
                  (sub) => sub.id === s.id
                );
                return (
                  <Box
                    key={s.id}
                    sx={{
                      scrollSnapAlign: 'center',
                      borderRadius: 10,
                      border: 'solid 1px',
                      borderColor: 'border',
                      ml: 2,
                      p: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <PricingItem
                      text="st"
                      cost={
                        s.cost[selected?.billingOccurance || 'monthly']
                          ?.employees
                      }
                    />

                    <PricingItem
                      cost={
                        (s.cost[selected?.billingOccurance || 'monthly']
                          ?.price ?? 0) /
                        (s.cost[selected?.billingOccurance || 'monthly']
                          ?.interval ?? 1)
                      }
                    />

                    {selected && (
                      <Button
                        sx={{ mt: 2, whiteSpace: 'nowrap' }}
                        color={selectedEmp ? 'secondary' : 'primary'}
                        onClick={() => onToggleExtra(s)}
                      >
                        {selectedEmp ? 'Ta bort' : 'Lägg till'}
                      </Button>
                    )}
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default PricingExtra;
