import React, {
  cloneElement,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Button, LinearProgress, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  component: ReactElement;
  componentProps?: { [key: string]: string };
  message: string;
  onConfirm: () => void;
}

const ConfirmButton: FC<Props> = ({
  onConfirm,
  component,
  componentProps = {},
  message,
}) => {
  const { t } = useTranslation();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [count, setCount] = useState(0);

  const handleCancel = useCallback(() => {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
      setCount(0);
    }
  }, [timer]);

  useEffect(() => {
    if (count >= 100) {
      handleCancel();
    }
  }, [count, handleCancel]);

  const handleClick = () => {
    if (!timer) {
      const id = setInterval(() => {
        setCount((curr) => curr + 4);
      }, 200);
      setTimer(id);
    }
  };

  const handleConfirm = useCallback(() => {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
      setCount(0);
    }
    onConfirm();
  }, [onConfirm, timer]);

  if (count === 0) {
    return cloneElement(component, { ...componentProps, onClick: handleClick });
  }

  return (
    <Box component="span" display="flex" flexShrink={1}>
      <Tooltip open arrow title={message}>
        <Box maxWidth="fit-content">
          <Box component="span" display="flex" flexShrink={1} flexGrow={0}>
            <Button
              variant="text"
              size="small"
              onClick={handleCancel}
              sx={{
                p: 0.25,
              }}
            >
              {t('NO')}
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={handleConfirm}
              sx={{
                p: 0.25,
              }}
            >
              {t('YES')}
            </Button>
          </Box>
          <LinearProgress
            value={100 - count}
            variant="determinate"
            sx={{ flexShrink: 1 }}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ConfirmButton;
