import { FC, useCallback } from 'react';
import {
  Slider,
  Box,
  Typography,
  Collapse,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { FiCornerRightDown } from 'react-icons/fi';
import { ServiceSubscription } from '@intly/core';

import ToggleButtons from '../../ui/ToggleButtons/ToggleButtons';
import { ToggleButton } from '../../ui/ToggleButtons/toggleButtons.model';
import { PricingChange } from './Pricing.model';
import PricingTiles from './PricingTiles';

interface PricingProps {
  pricingType: 'course' | 'service';
  onPricingTypeChange?: (type: 'course' | 'service') => void;
  subscriptions: ServiceSubscription[];
  onSelectionChanged?: (change: PricingChange) => void;
  selected?: PricingChange;
}

const billingButtons: ToggleButton[] = [
  {
    id: 'monthly',
    title: 'Fakturera månadsvis',
  },
  {
    id: 'annual',
    title: 'Fakturera årsvis',
  },
];

const servicefeatures = [
  {
    id: 'PAY_PULSE',
    text: 'Lönepulsen',
  },
  {
    id: 'PAYROLL',
    text: 'Löneanalys',
  },
  {
    id: 'PAYROLL',
    text: 'Bevakningar',
  },
];

const courseFeatures = [
  {
    id: 'COURSE',
    text: 'Kurser',
  },
  {
    id: 'COURSE_ENV',
    text: 'Kursmiljö',
  },
];

const features = {
  course: courseFeatures,
  service: servicefeatures,
};

const DEFAULT_SUBSCIPTION: PricingChange = {
  billingOccurance: 'annual',
};

const Pricing: FC<PricingProps> = ({
  pricingType,
  onPricingTypeChange,
  subscriptions = [],
  onSelectionChanged,
  selected = DEFAULT_SUBSCIPTION,
}) => {
  const handleBillingChange = useCallback(
    (billingOccurance: string) => {
      const next: PricingChange = {
        ...selected,
        billingOccurance: billingOccurance as 'monthly' | 'annual',
      };
      if (onSelectionChanged) onSelectionChanged(next);
    },
    [selected, onSelectionChanged]
  );

  const handleSelectSubscription = useCallback(
    (id: string) => {
      const next: PricingChange = {
        ...selected,
        subscrition: subscriptions.find((s) => s.id === id),
      };
      if (onSelectionChanged) onSelectionChanged(next);
    },
    [selected, onSelectionChanged, subscriptions]
  );

  const subs = subscriptions.filter(
    (s) =>
      (pricingType === 'course' && s.course) ||
      (pricingType === 'service' && !s.course)
  );

  const companySubs = subs.filter(
    (s) => !s.propertys?.some((p) => p.key === 'private' && p.value === 'true')
    && Boolean(s.cost[selected.billingOccurance])
  );

  const privateSubs = subs.filter((s) =>
    s.propertys?.some((p) => p.key === 'private' && p.value === 'true')
  && Boolean(s.cost[selected.billingOccurance])
  );

  return (
    <Box overflow="hidden">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" justifyContent="center">
          <Typography>Spara upp till 2 månader!</Typography>
          <Box component="span" marginLeft={1} marginTop={1}>
            <FiCornerRightDown size="30px" />
          </Box>
        </Box>
        <Box
          bgcolor="primary"
          sx={{
            backgroundColor: 'primary.main',
            p: 0.5,
            borderRadius: '99em',
            width: 'fit-content',
          }}
        >
          <ToggleButtons
            darkBackground
            color="secondary"
            buttons={billingButtons}
            onChange={handleBillingChange}
            defaultSelected={selected?.billingOccurance}
          />
        </Box>
      </Box>

      {onPricingTypeChange && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 1,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={pricingType === 'course' ? true : false}
                onChange={(e, checked) => {
                  onPricingTypeChange(checked ? 'course' : 'service');
                }}
              />
            }
            label="Visa kursprenumerationer"
          />
        </Box>
      )}

      <Box sx={{ mt: 2, mx: 2 }}>
        <Typography textAlign="left">
          Det är ingen bindningstid på våra prenumerationer och du närsomhelst
          avsluta din prenumeration med några klick. Om inte annat anges, så
          förnyas din prenumeration automatisk efter varje faktureringsperiod
          tills du avslutar prenumerationen. Vid avslut kan du naturligtvis
          nyttja tjänsten tills befintlig faktureringsperiod löper ut.
          Faktueringsperiod är månad eller år.
        </Typography>
        {privateSubs.length > 0 && (
          <Typography variant="h5" sx={{ textAlign: 'left' }}>
            Företag
          </Typography>
        )}
        <PricingTiles
          pricingType={pricingType}
          subscriptions={companySubs}
          billingOccurance={selected?.billingOccurance}
          selectedSusbscription={selected.subscrition?.id}
          onSelectSubscription={handleSelectSubscription}
          features={features[pricingType]}
        />

        {privateSubs.length > 0 && (
          <>
            <Typography variant="h5" sx={{ mt: 4, textAlign: 'left' }}>
              Privatperson
            </Typography>
            <PricingTiles
              privatePerson
              pricingType={pricingType}
              subscriptions={privateSubs}
              billingOccurance={selected?.billingOccurance}
              selectedSusbscription={selected.subscrition?.id}
              onSelectSubscription={handleSelectSubscription}
              features={features[pricingType]}
            />
            <Typography variant="body2" sx={{ mt: 4, textAlign: 'left' }}>
              <strong>Ångerrätt:</strong> Enligt EU-lag har alla
              distansavtalskunder rätt att ångra sitt köp inom 14 dagar från
              köptillfället. Ångerrätten gäller endast konsumenter och inte
              företag.
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Pricing;
