import { FC, ReactNode } from 'react';
import {
  Box,
  Button,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { formatNumbersStr, ServiceSubscription } from '@intly/core';
import { IoCheckmarkOutline, IoCloseOutline } from 'react-icons/io5';

interface Props {
  pricingType: 'course' | 'service';
  subscriptions: ServiceSubscription[];
  billingOccurance: 'monthly' | 'annual';
  selectedSusbscription?: string;
  onSelectSubscription: (id: string) => void;
  features: any[];
  privatePerson?: boolean;
}

const PricingTiles: FC<Props> = ({
  pricingType,
  subscriptions,
  billingOccurance,
  selectedSusbscription,
  onSelectSubscription,
  features,
  privatePerson,
}) => {
  const { palette, breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('sm'));

  return (
    <TableContainer
      sx={{ height: 'max-content', mt: 4, mb: isTablet ? 10 : 0 }}
    >
      <Table
        size="small"
        sx={{
          height: '1px', // super hack to get full height content in cell
          overflow: 'auto',
          scrollSnapType: 'x mandatory',
        }}
      >
        <TableBody>
          <PricingRow
            isFirst
            components={subscriptions.map((subscription) => (
              <Box sx={{ height: 16 }} />
            ))}
          />
          <PricingRow
            components={subscriptions.map((subscription) => (
              <Typography variant="h6">{subscription.name}</Typography>
            ))}
          />

          <PricingRow
            title={'\u00a0'}
            subtitle={'\u00a0'}
            components={subscriptions.map((subscription) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <PricingItem
                  cost={
                    billingOccurance === 'annual'
                      ? (subscription.cost[billingOccurance]?.price || 0) / 12
                      : subscription.cost[billingOccurance]?.price || 0
                  }
                />
                {privatePerson && <PricingItem text={`inkl. moms`} />}
                {billingOccurance === 'annual' && (
                  <PricingItem
                    text={`${
                      subscription.cost[billingOccurance]?.price || 0
                    }\u00a0kr\u00a0/\u00a0år`}
                  />
                )}
              </Box>
            ))}
          />

          <PricingRow
            subtitle="Funtioner"
            components={subscriptions.map((subscription) => (
              <Box />
            ))}
          />

          {features.map(({ id, text }, i) => (
            <PricingRow
              key={`features-${i}`}
              title={text}
              components={subscriptions.map((subscription) => {
                const isIncluded = subscription?.features?.includes(id);
                return (
                  <Box
                    sx={{
                      my: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '30px',
                      width: '30px',
                      borderRadius: '50%',
                      border: '1px solid',
                      borderColor: isIncluded ? 'success.main' : 'border',
                    }}
                  >
                    {isIncluded ? (
                      <IoCheckmarkOutline
                        fontSize="24px"
                        color={palette.success.main}
                      />
                    ) : (
                      <IoCloseOutline fontSize="24px" color={palette.border} />
                    )}
                  </Box>
                );
              })}
            />
          ))}

          <PricingRow
            components={subscriptions.map((subscription) => {
              return (
                <Box sx={{ my: 1, width: 1 }}>
                  {selectedSusbscription !== subscription?.id && (
                    <Button
                      fullWidth
                      onClick={() => onSelectSubscription(subscription?.id)}
                      color="primary"
                      variant="contained"
                    >
                      Välj
                    </Button>
                  )}
                  {selectedSusbscription === subscription?.id && (
                    <Radio checked={true} color="secondary" />
                  )}
                </Box>
              );
            })}
          />

          <PricingRow
            title={'\u00a0'}
            components={subscriptions.map((subscription) => (
              <Box />
            ))}
          />

          <PricingRow
            title="Användare"
            components={subscriptions.map((subscription) => (
              <PricingItem
                cost={subscription.cost[billingOccurance]?.users || 0}
                text="st"
              />
            ))}
          />

          {pricingType === 'service' && (
            <PricingRow
              title="Anställda"
              components={subscriptions.map((subscription) => {
                const isFree = subscription?.propertys?.find(
                  (prop) => prop.key === 'free'
                )?.value;
                return (
                  <>
                    {isFree && <Typography variant="h4">~</Typography>}
                    {!isFree && (
                      <PricingItem
                        text="st"
                        cost={
                          subscription.cost[billingOccurance]?.employees || 0
                        }
                      />
                    )}
                  </>
                );
              })}
            />
          )}

          {pricingType === 'service' && (
            <PricingRow
              title="År av data"
              components={subscriptions.map((subscription) => {
                const isFree = subscription?.propertys?.find(
                  (prop) => prop.key === 'free'
                )?.value;
                return (
                  <>
                    {isFree && <Typography variant="h4">~</Typography>}
                    {!isFree && (
                      <PricingItem
                        text="år"
                        cost={subscription.cost[billingOccurance]?.years || 0}
                      />
                    )}
                  </>
                );
              })}
            />
          )}

          <PricingRow
            isLast
            components={subscriptions.map((subscription) => (
              <Box sx={{ height: 16 }} />
            ))}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface PricingRowProps {
  title?: string;
  subtitle?: string;
  isFirst?: boolean;
  isLast?: boolean;
  components: ReactNode[];
}

export const PricingRow: FC<PricingRowProps> = ({
  title,
  subtitle,
  components,
  isFirst,
  isLast,
}) => {
  return (
    <TableRow sx={{ minHeight: 40, height: 1 }}>
      <TableCell
        align="justify"
        sx={{
          zIndex: 1,
          bgcolor: 'background.paper',
          border: 'unset',
          p: 0,
          pr: 2,
          position: 'sticky',
          left: 0,
        }}
      >
        <Typography variant="h6" sx={{ lineHeight: 1 }}>
          {title}
        </Typography>
        <Typography variant="overline">{subtitle}</Typography>
      </TableCell>
      {components.map((component, i) => (
        <TableCell
          key={i}
          align="center"
          sx={{ border: 'unset', p: 0, pr: 2, height: 1 }}
        >
          <Box
            sx={{
              scrollSnapAlign: 'start',
              height: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              border: '2px solid',
              px: 2,
              width: 180,
              pt: isFirst ? 2 : 0,
              borderTop: isFirst ? 'solid' : 'unset',
              borderTopLeftRadius: isFirst ? '40px' : undefined,
              borderTopRightRadius: isFirst ? '40px' : undefined,
              pb: isLast ? 2 : 0,
              borderBottom: isLast ? 'solid' : 'unset',
              borderBottomLeftRadius: isLast ? '40px' : undefined,
              borderBottomRightRadius: isLast ? '40px' : undefined,
              borderColor: 'border',
            }}
          >
            {component}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );
};

export const PricingItem = ({
  cost,
  text = 'kr / månad',
}: {
  cost?: number;
  text?: string;
}) => (
  <Box
    sx={{
      display: 'flex',
      height: 1,
      width: 1,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'flex-start',
    }}
  >
    {!isNaN(cost as any) && (
      <Typography variant="h5">{formatNumbersStr(cost || 0, 0)}</Typography>
    )}

    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      marginLeft={1}
    >
      <Typography variant="overline">{text}</Typography>
    </Box>
  </Box>
);

export default PricingTiles;
