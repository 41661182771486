import { gql } from '@apollo/client';

export const FILTERS_QUERY = gql`
  query filters($groupId: ID!) {
    filters(groupId: $groupId) {
      property
      values
    }
  }
`;
