import { FC, ReactElement, useMemo } from 'react';
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Navbar from '../../Navbar/Navbar';
import Subscribe from '../Subscribe/Subscribe';
import ContactDialog from '../ContactDialog/ContactDialog';
import IndexHeader from './IndexHeader';
import logo from '../../../assets/logo-white.png';
import leviMidninght from '../../../assets/levi-midnight.jpg';
import goraSitt from '../../../assets/loneavdelningen-gora-sitt.jpg';
import nyttSatt from '../../../assets/visa-lon-nytt-satt.jpg';
import teamPulse from '../../../assets/team-pulse.jpg';
import CustomerLogs from './CustomerLogs';

const headerTextBackground = 'rgba(0, 0, 0, 0.5)';

const getHeaderBackground = (slug: string | undefined) => {
  const defaults = {
    background: {
      backgroundSize: 'cover !important',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      background: `url(${leviMidninght})`,
      color: 'common.white',
    },
    heading: {},
    subheading: {
      bgcolor: {
        xs: headerTextBackground,
        md: 'unset',
      },
    },
  };

  if (!slug) {
    return defaults;
  }

  switch (slug) {
    case 'pricing':
      return {
        ...defaults,
        background: {
          ...defaults.background,
          background: `url(${nyttSatt})`,
        },
      };
    case 'blog':
      return {
        ...defaults,
        background: {
          ...defaults.background,
          background: `url(${goraSitt})`,
        },
        subheading: {
          bgcolor: 'unset',
        },
      };
    case 'lonepulsen':
      return {
        ...defaults,
        background: {
          ...defaults.background,
          background: `url(${teamPulse})`,
        },
        heading: {
          ...defaults.heading,
          bgcolor: headerTextBackground,
        },
      };
    case 'payroll-analysis':
      return defaults;
    default:
      return defaults;
  }
};

export interface GatsbyHeaderProps {
  isIndex: boolean;
  title: string;
  subtitle: string;
  dark: boolean;
  navItems: ReactElement[];
  mobileNav: ReactElement;
  linkComponent?: ReactElement;
  slug?: string;
  logoClick: () => void;
}

const GatsbyHeader: FC<GatsbyHeaderProps> = ({
  isIndex = false,
  title,
  subtitle,
  dark = true,
  linkComponent,
  navItems,
  slug,
  mobileNav,
  logoClick,
}) => {
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const headerStyles = useMemo(() => getHeaderBackground(slug), [slug]);
  return (
    <>
      <Navbar
        maxWidth="lg"
        sx={{
          bgcolor: 'rgba(0, 0, 0, 0.5)',
        }}
        linkComponent={linkComponent}
        navItems={desktop ? navItems : []}
        rightItems={
          desktop ? (
            <Stack direction="row" spacing={2}>
              <ContactDialog />
              <Button
                variant="text"
                color="secondary"
                LinkComponent={Link}
                href="https://www.portal.intly.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                Logga in
              </Button>
            </Stack>
          ) : (
            mobileNav
          )
        }
        logo={
          <Box
            component="img"
            sx={{
              width: 'auto',
              height: 64,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            src={logo}
            onClick={logoClick}
          />
        }
      />
      {isIndex ? (
        <IndexHeader />
      ) : (
        <Box
          sx={{
            ...headerStyles.background,
            minHeight: '400px',
            paddingTop: 8,
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'flex-start',
          }}
        >
          <Container maxWidth="lg">
            <Typography
              paragraph
              variant="h2"
              sx={{
                background:
                  'linear-gradient(180deg, rgba(138,86,172,1) 0%, rgba(212,127,166,1) 100%)',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 700,
                ...headerStyles.heading,
              }}
            >
              {title}
            </Typography>
            <Typography variant="h5" sx={headerStyles.subheading}>
              {subtitle}
            </Typography>
            {slug === 'blog' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 4,
                  mb: 10,
                }}
              >
                <Subscribe />
              </Box>
            )}
            {slug === 'education' && (
              <Box
                sx={{
                  mt: 4,
                }}
              >
                <CustomerLogs />
              </Box>
            )}
          </Container>
        </Box>
      )}
    </>
  );
};

export default GatsbyHeader;
