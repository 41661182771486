import { gql } from '@apollo/client/core';

export const report = gql`
  query report($groupId: ID!, $selection: [String!], $target: String) {
    report(groupId: $groupId, selection: $selection, target: $target) {
      filename
      period
      sortKey
      report {
        rownumber
        employment_number
        costcenter
        paycode
        from
        to
        quantity
        oldPaycode
        number
        transform {
          failed
          message {
            message
            type
          }
        }
      }
    }
  }
`;

export const reportV2 = gql`
  query report($groupId: ID!, $selection: [String!], $target: String) {
    reportV2(groupId: $groupId, selection: $selection, target: $target) {
      filename
      period
      sortKey
      report {
        rownumber
        employment_number
        costcenter
        paycode
        from
        to
        quantity
        oldPaycode
        number
        transform {
          failed
          message {
            message
            type
          }
        }
      }
    }
  }
`;

export const createReport = gql`
  mutation createReport($groupId: ID!, $selection: [String!], $selectionV2: [SelectionInput], $target: String) {
    createReport(groupId: $groupId, selection: $selection, selectionV2: $selectionV2, target: $target) {
      id
      created
      status {
        value
        timestamp
      }
    }
  }
`;

export const getReportStatus = gql`
  mutation statusReport($groupId: ID!, $id: ID!) {
    statusReport(groupId: $groupId, id: $id) {
      id
      created
      status {
        value
        timestamp
      }
    }
  }
`;

export const reportList = gql`
  query reportList($groupId: ID!, $period: String, $target: String = "Hogia") {
    reportList(groupId: $groupId, period: $period, target: $target) {
      filename
      period
      sortKey
      sourceFiles {
        filename
        period
      }
    }
  }
`;

export const customerMetaSettings = gql`
  query customerMetaSettings($groupId: ID!, $settingType: SettingType) {
    customerMetaSettings(groupId: $groupId, settingType: $settingType)
  }
`;

export const reportFileList = gql`
  query reportFileList(
    $groupId: ID!
    $from: String
    $to: String
    $target: String = "Hogia"
  ) {
    reportFileList(
      groupId: $groupId
      type: [INPUT, BOOKKEEPING]
      range: { from: $from, to: $to }
      target: $target
    ) {
      file {
        filename
        sortKey
        period
      }
      filename
      routine{
        period
        routine
      }
      reportList {
        filename
        period
        sortKey
        info{
          error
          list
        }
      }
    }
  }
`;
