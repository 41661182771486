import { grey } from '@mui/material/colors';
import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@mui/material/styles';
import { muiThemeOverrides } from './overrides';

export const muiLightPalette: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: '#f1f0f2',
    },
    primary: {
      main: '#8a56ac',
      light: '#CED6FF',
      dark: '#5f4591',
    },
    secondary: {
      main: '#d47fa6',
      light: '#dc98b7',
      dark: '#945874',
      contrastText: '#FFF',
    },
    success: {
      main: '#1DB753',
      light: '#A4F7A8',
      dark: '#096A47',
      contrastText: '#FFF',
    },
    warning: {
      main: '#FFBB00',
      light: '#FFEC99',
      dark: '#936000',
    },
    error: {
      main: '#E2315E',
      light: '#FCAFAD',
      dark: '#830F4E',
    },
    other: {
      greenContrast: '#34FDD6',
      emptyChartFill: grey[200],
    },
    border: '#E7E4E9',
  },
};

const theme = createTheme({ ...muiThemeOverrides, ...muiLightPalette });

export const muiLightTheme = responsiveFontSizes(theme);
