import { gql } from '@apollo/client';

export const ADD_INQUIRY_MUTATION = gql`
  mutation addInquiry($input: InquiryInput!) {
    addInquiry(input: $input) {
      email
      reason
      company
      firstName
      lastName
      message
      date
    }
  }
`;
