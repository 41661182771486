import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import {
  ToggleButtonGroup,
  ToggleButton as MUIToggleButton,
  Skeleton,
} from '@mui/material';
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { ToggleButton } from './toggleButtons.model';

const skeletonStyles = {
  borderRadius: '22px',
  marginRight: 0.5,
  height: '54px',
  width: '150px',
};

export const ToggleButtonsSkeleton: FC = () => {
  return (
    <Box
      component="span"
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: 0.5,
      }}
    >
      <Skeleton sx={skeletonStyles} />
      <Skeleton sx={skeletonStyles} />
      <Skeleton sx={skeletonStyles} />
    </Box>
  );
};

export interface ToggleButtonProps {
  buttons: ToggleButton[];
  onChange?: (selected: string) => void;
  onItemsChange?: (selected: string[]) => void;
  onMouseOver?: (id: string) => void;
  color?: 'primary' | 'secondary';
  defaultSelected?: string;
  selectedOutside?: string;
  selectedItems?: string[];
  size?: 'large' | 'medium' | 'small';
  darkBackground?: boolean;
  exclusive?: boolean;
  loading?: boolean;
  disableScroll?: boolean;
}

const ToggleButtons: FC<ToggleButtonProps> = ({
  buttons,
  size,
  onChange,
  onItemsChange,
  onMouseOver,
  color = 'primary',
  selectedItems,
  defaultSelected,
  selectedOutside,
  darkBackground,
  exclusive = true,
  loading,
  disableScroll,
}) => {
  const { palette, breakpoints } = useTheme();
  const [selected, setSelected] = useState<string>();
  const [displayScrollBtn, setDisplayScrollBtn] = useState(false);
  const [leftScrollDisabled, setLeftScrollDisabled] = useState(true);
  const [rightScrollDisabled, setrightScrollDisabled] = useState(false);
  const ref = useRef<HTMLSpanElement>();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  useEffect(() => {
    if (buttons && buttons.length > 0) {
      if (defaultSelected) {
        setSelected(defaultSelected);
      } else if (!selectedOutside) {
        setSelected(buttons[0].id);
      }
    }
  }, [buttons, defaultSelected]);

  useEffect(() => {
    if (selectedOutside) {
      setSelected(selectedOutside);
    }
  }, [selectedOutside]);

  useLayoutEffect(() => {
    const { current } = ref;
    if (current && current.clientWidth < current.scrollWidth && !isMobile) {
      setDisplayScrollBtn(true);
    } else {
      setDisplayScrollBtn(false);
    }
  }, [ref, buttons, setDisplayScrollBtn, isMobile]);

  const handleSelected = (
    event: React.MouseEvent<HTMLElement>,
    selected: string
  ) => {
    if (!exclusive && selected !== null && onItemsChange) {
      onItemsChange(selected);
    } else if (selected !== null && onChange) {
      setSelected(selected);
      onChange(selected);
    }
  };

  const handleScroll = (dir: 'LEFT' | 'RIGHT') => {
    const { current } = ref;
    const currScroll = current?.scrollLeft || 0;
    const newScroll = currScroll + (dir === 'LEFT' ? -100 : 100);
    ref.current?.scrollTo({
      top: 0,
      left: newScroll,
      behavior: 'smooth',
    });

    if (
      newScroll + (current?.clientWidth || 0) >=
      (current?.scrollWidth || 0)
    ) {
      setrightScrollDisabled(true);
    } else {
      setrightScrollDisabled(false);
    }
    if (newScroll <= 0) {
      setLeftScrollDisabled(true);
    } else {
      setLeftScrollDisabled(false);
    }
  };

  const handleMouseOver = (id: string) => {
    if (onMouseOver) {
      onMouseOver(id);
    }
  };

  const useDarkBackground =
    palette.mode === 'dark' ? true : darkBackground || false;

  if (loading) {
    return <ToggleButtonsSkeleton />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
      }}
    >
      {!disableScroll && displayScrollBtn && (
        <IconButton
          disabled={leftScrollDisabled}
          onClick={() => handleScroll('LEFT')}
          size={size}
          sx={{
            color: useDarkBackground ? '#FFF' : undefined,
          }}
        >
          <IoChevronBackOutline />
        </IconButton>
      )}
      <ToggleButtonGroup
        exclusive={exclusive}
        sx={{
          ml: 2,
          backgroundColor: 'transparent',
          overflow: 'hidden',
          overflowX: {
            xs: 'auto',
            sm: 'hidden',
          },
          whiteSpace: 'nowrap',
          position: 'relative',
          margin: '0',
          '& .MuiToggleButtonGroup-grouped': {
            margin: 0.5,
            border: 'unset',
            '&:not(:first-of-type)': {
              borderRadius: '99em',
            },
            '&:first-of-type': {
              borderRadius: '99em',
            },
          },
        }}
        value={exclusive ? selected : selectedItems}
        size={size}
        onChange={handleSelected}
        ref={ref}
      >
        {buttons.map((b) => (
          <MUIToggleButton
            key={b.id}
            value={b.id}
            disabled={b.disabled}
            onMouseOver={() => handleMouseOver(b.id)}
            sx={[
              {
                '&.Mui-selected': {
                  backgroundColor: `${[color]}.main`,
                  color: `${[color]}.contrastText`,
                },
              },
              useDarkBackground && {
                color: '#FFF',
              },
            ]}
          >
            {b.title}
          </MUIToggleButton>
        ))}
      </ToggleButtonGroup>
      {!disableScroll && displayScrollBtn && (
        <IconButton
          disabled={rightScrollDisabled}
          onClick={() => handleScroll('RIGHT')}
          size={size}
          sx={{
            color: useDarkBackground ? '#FFF' : undefined,
          }}
        >
          <IoChevronForwardOutline />
        </IconButton>
      )}
    </Box>
  );
};

export default ToggleButtons;
