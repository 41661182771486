import { ReactElement, ReactNode, forwardRef } from 'react';
import {
  Box,
  Card as MuiCard,
  CardActionArea,
  CardProps as MuiCardProps,
  SxProps,
  Typography,
} from '@mui/material';
import { muiDarkTheme } from '../../theme/darkTheme';

export interface CardBaseProps extends MuiCardProps {
  header?: string;
  preHeaderComponent?: ReactElement;
  padding?: number;
  active?: boolean;
  canBeActivated?: boolean;
  onClick?: () => void;
  borderRadiusTopLeft?: 'large' | 'normal' | 'none';
  borderRadiusTopRight?: 'large' | 'normal' | 'none';
  borderRadiusBottomLeft?: 'large' | 'normal' | 'none';
  borderRadiusBottomRight?: 'large' | 'normal' | 'none';
  sx?: SxProps;
}

const CardBase = forwardRef<HTMLDivElement, CardBaseProps>(
  (
    {
      header,
      preHeaderComponent,
      children,
      color,
      active = false,
      canBeActivated = false,
      onClick,
      padding = 5,
      borderRadiusTopLeft = 'normal',
      borderRadiusTopRight = 'normal',
      borderRadiusBottomLeft = 'normal',
      borderRadiusBottomRight = 'normal',
      sx = {},
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        {onClick && (
          <MuiCard
            ref={ref}
            sx={[
              {
                borderRadius: 10,
                boxShadow: 'unset',
              },
              canBeActivated && {
                border: '2px solid',
                borderColor: 'background.paper',
              },
              active && {
                border: '2px solid',
                borderColor: 'border',
              },
              (color === 'PURPLE' || color === 'primary') && {
                bgcolor: 'primary.main',
              },
              color === 'SALOMON' && {
                bgcolor: 'secondary.main',
              },
              color === 'BLUE' && {
                bgcolor: '#4EBDEF',
              },
              color === 'darkPaper' && {
                bgcolor: muiDarkTheme.palette?.background?.paper,
              },
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...restProps}
          >
            <CardActionArea
              sx={{
                p: padding || 5,
                textAlign: 'center',
              }}
              onClick={onClick}
            >
              {preHeaderComponent}
              {header && (
                <Typography sx={{ my: 2 }} variant="h5">
                  {header}
                </Typography>
              )}
              {children}
            </CardActionArea>
          </MuiCard>
        )}
        {!onClick && (
          <MuiCard
            ref={ref}
            sx={[
              {
                borderRadius: 10,
                boxShadow: 'unset',
                bgcolor: color,
              },
              active && {
                border: '2px solid',
                borderColor: 'border',
              },
              (color === 'PURPLE' || color === 'primary') && {
                bgcolor: 'primary.main',
              },
              color === 'SALOMON' && {
                bgcolor: 'secondary.main',
              },
              color === 'BLUE' && {
                bgcolor: '#4EBDEF',
              },
              color === 'darkPaper' && {
                bgcolor: muiDarkTheme.palette?.background?.paper,
              },
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...restProps}
          >
            <Box textAlign="center" padding={padding} height="100%">
              {preHeaderComponent}
              {header && (
                <Typography sx={{ my: 2 }} variant="h5">
                  {header}
                </Typography>
              )}
              {children}
            </Box>
          </MuiCard>
        )}
      </>
    );
  }
);

interface CardProps extends CardBaseProps {
  lineHeader?: string;
  lineHeaderIcon?: ReactNode;
  lineHeaderSx?: SxProps;
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ lineHeader, lineHeaderIcon, lineHeaderSx = {}, ...rest }, ref) => {
    if (lineHeader) {
      return (
        <Box
          ref={ref}
          sx={[
            {
              height: 1,
              position: 'relative',
            },
          ]}
        >
          <CardBase {...rest} />
          {lineHeader && (
            <Typography
              sx={[
                {
                  my: 2,
                  position: 'absolute',
                  top: -18,
                  left: (rest?.padding ?? 1) * 4 + 20,
                  m: 1,
                  color: 'text.primary',
                  bgcolor: 'background.paper',
                },
                ...(Array.isArray(lineHeaderSx)
                  ? lineHeaderSx
                  : [lineHeaderSx]),
              ]}
              // variant="h5"
            >
              {lineHeader} {lineHeaderIcon}
            </Typography>
          )}
        </Box>
      );
    }

    return <CardBase {...rest} />;
  }
);

export default Card;
