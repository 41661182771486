import { removeMembershipFromMeCache } from './../user/user.cache';
import { Membership, UserRole } from './../user/user.model';
import { ApolloCache, gql } from '@apollo/client';
import { Group } from './group.model';

export const membersFragmentOnGroup = gql`
  fragment Memberships on Group {
    members {
      id
      memberAt
      role
      status
    }
  }
`;

export const inviteMemberCacheUpdate = (
  cache: ApolloCache<any>,
  groupId: string,
  data: Membership
) => {
  const cacheGroup = cache.readFragment<Group>({
    id: `Group:${groupId}`,
    fragment: membersFragmentOnGroup,
  });

  if (cacheGroup) {
    const newGroup = {
      ...cacheGroup,
      members: [...cacheGroup.members, data],
    };

    cache.writeFragment({
      id: `Group:${groupId}`,
      fragment: membersFragmentOnGroup,
      data: newGroup,
    });
  }
};

export const removeMemberFromCache = (
  cache: ApolloCache<any>,
  data: Membership,
  groupId: string,
  role: UserRole
) => {
  const cacheGroup = cache.readFragment<Group>({
    id: `Group:${groupId}`,
    fragment: membersFragmentOnGroup,
  });

  if (cacheGroup) {
    const newGroup = {
      ...cacheGroup,
      members: cacheGroup.members.filter((t) => t.id !== data.id),
    };

    cache.writeFragment({
      id: `Group:${groupId}`,
      fragment: membersFragmentOnGroup,
      data: newGroup,
    });
  }

  if (!['ADMIN', 'SUPERADMIN'].includes(role)) {
    removeMembershipFromMeCache(cache, data);
  }
};
