import {
  Box,
  Button,
  ButtonProps,
  Card,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IoMailOutline } from 'react-icons/io5';
import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { useGatsby } from '@intly/core';

export interface SubscribeProps {
  buttonProps?: ButtonProps;
  buttonText?: string;
  tooltipSuccess?: string;
  useMobile?: boolean;
  onExternalSubmit?: (email: string) => void;
}

const Subscribe: FC<SubscribeProps> = ({
  buttonText = 'Prenumerera',
  tooltipSuccess = 'Du prenumerarar nu på Inlty nyhetsbrev',
  useMobile,
  buttonProps = {},
  onExternalSubmit,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { addInquiry } = useGatsby();
  const [tooltip, setTooltip] = useState('');

  const schema = Yup.object({
    email: Yup.string()
      .email('Fel format på e-post adress')
      .required('E-post adress är obligatoriskt'),
  });

  useEffect(() => {
    if (tooltip) {
      const timer = setTimeout(() => {
        setTooltip('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [tooltip]);

  const {
    register,
    handleSubmit: onSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const handleInvalid = (invalid: any) => {
    setTooltip(invalid.email.message);
  };

  const handleValid = (valid: any) => {
    setTooltip(tooltipSuccess);
    if (!onExternalSubmit) {
      addInquiry({ ...valid, reason: 'SUBSCRIBE' });
    } else {
      onExternalSubmit(valid.email);
    }
    setValue('email', '');
  };

  return (
    <Tooltip arrow placement="top" title={tooltip} open={Boolean(tooltip)}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          px: 1,
        }}
      >
        <Card
          sx={{
            display: 'flex',
            p: 1,
            borderRadius: '99em',
            bgcolor: 'primary.light',
            alignItems: 'center',
            maxWidth: '420px',
            width: '100%',
          }}
        >
          <IoMailOutline
            style={{
              fontSize: '25px',
              color: theme.palette.secondary.main,
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
            }}
          />
          <Box
            component="input"
            sx={{
              backgroundColor: 'transparent',
              border: 'none',
              outline: 'none',
              caretColor: theme.palette.secondary.main,
              fontSize: '16px',
              flex: 1,
            }}
            {...register('email')}
            placeholder="din@epost.se"
          />
          {!useMobile && !isMobile && (
            <Button
              color="secondary"
              onClick={onSubmit(handleValid, handleInvalid)}
            >
              {buttonText}
            </Button>
          )}
        </Card>
        {(useMobile || (!useMobile && isMobile)) && (
          <Button
            sx={{
              mt: 2,
            }}
            color="secondary"
            onClick={onSubmit(handleValid, handleInvalid)}
            {...buttonProps}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Tooltip>
  );
};

export default Subscribe;
