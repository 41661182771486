import { FC } from 'react';
import { GatsbyContext } from './GatsbyContext';
import { useMutation } from '@apollo/client';
import { ADD_INQUIRY_MUTATION } from '../common.queries';
import { InquiryForm } from './GatsbyInterface';
declare const window: any;

export interface GatsbyProviderProps {
  children: React.ReactNode;
}

export const GatsbyProvider: FC<GatsbyProviderProps> = ({ children }) => {
  const [subscibe] = useMutation(ADD_INQUIRY_MUTATION);

  const addEvent = (reason: string) => {
    if (window && window.gtag) {
      window.gtag('event', `${reason}_CLICK`);
    }
  };

  const addInquiry = (input: InquiryForm) => {
    addEvent(input.reason);
    subscibe({
      variables: { input },
    });
  };

  return (
    <GatsbyContext.Provider
      value={{
        addInquiry,
        addEvent,
      }}
    >
      {children}
    </GatsbyContext.Provider>
  );
};
