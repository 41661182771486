import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import GatsbyVideoSection from '../GatsbyPageSection/GatsbyVideoSection';
import promoVideo from '../../../assets/Intly_Promo_slutlig.mp4';
import nastaNiva from '../../../assets/nasta-niva.jpg';
import CustomerLogs from './CustomerLogs';

const IndexHeader: FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Box
        sx={{
          // backgroundColor: '#320047',
          backgroundImage: `url(${nastaNiva})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '100vh',
          // pt: 20,
          color: 'common.white',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            height: '100vh',
            display: 'flex',
            alignItems: 'flex-end',
            width: 1,
            overflow: 'hidden',
          }}
        >
          <CustomerLogs />
        </Box>
        <Container maxWidth="lg" sx={{ height: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 1,
            }}
          >
            <Box position={mobile ? 'relative' : 'absolute'} maxWidth={420}>
              <Box display="flex" flexWrap="wrap">
                <Typography
                  variant="h2"
                  sx={{
                    background:
                      'linear-gradient(180deg, rgba(138,86,172,1) 0%, rgba(212,127,166,1) 100%)',
                    backgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 2,
                    fontWeight: 700,
                  }}
                >
                  Lönearbetet till nästa nivå.
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    bgcolor: {
                      xs: 'rgba(0, 0, 0, 0.5)',
                      md: 'unset',
                    },
                  }}
                >
                  Intly gör så att ni kan ta nästa steg i er utveckling som
                  löneavdelning. Enkelt att lägga till och ni behöver inte byta
                  ert lönesystem.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box sx={{ my: 10, mx: 4 }}>
          <Typography variant="h4">
            Alla inom lön förtjänar bra verktyg. För vi tror lön är en viktig
            del av alla organisationer. Därför tar vi fram tjänster inom lön som
            gör arbetet enklare, smartare och roligare. Som du kan anvanda utan
            att byta lönesystem. Allt i en plattform och går att komma igång på
            nolltid.
          </Typography>
        </Box>

        <GatsbyVideoSection
          category="Se själv"
          title="Gå från lönadmin till lönecontroller på 20 sekunder."
          video={promoVideo}
        />
      </Container>
    </div>
  );
};

export default IndexHeader;
