import { gql } from "@apollo/client";

export const COURSE_INFO = gql`
query courseInfo($groupId: ID!) {
  courseInfo(groupId: $groupId){
    defaultCourseTenant{
      name
      type
      groupId
    }
    availableCourses{
      name
      type
      groupId
    }
  }
}
`;

export const SET_DEFAULT_COURSE_TENANT = gql`
mutation setAsDefaultCourseTenant($groupId: ID!) {
  setAsDefaultCourseTenant(groupId: $groupId){
      name
      type
      groupId
  }
}
`;

export const CREATE_NEW_COURSE = gql`
mutation createCourse($name: String!, $type: String!) {
  createCourse(name: $name, type: $type){
      name
      type      
      groupId
  }
}
`;

export const CONVERT_COURSE = gql`
mutation createCourse($groupId: String!, $type: String!) {
  convertToCourseTenant(groupId: $ngroupIdame, type: $type){
      name
      type      
      groupId
  }
}
`;