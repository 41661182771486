import React, { FC, useCallback, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import AreaChart from '../AreaChart/AreaChart';
import { ChartConfig, ChartDataSet } from '@intly/core';
import Card from '../../../ui/Card/Card';
import { hexToRgba } from '../../../utils/hexToRgba';
import { Box } from '@mui/material';

interface CounterProps {
  title: string;
  value: number;
  compareTo?: number;
  helpText?: string;
  history?: ChartDataSet;
  chartConfig?: ChartConfig;
}

const styles = {
  root: {
    minHeight: '150px',
    maxHeight: '150px',
    position: 'relative',
    overflow: 'unset',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 2,
  },
  chart: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: 'common.white',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '18px',
    margin: 0,
  },
  value: {
    fontSize: '38px',
    fontWeight: 'fontWeightBold',
    margin: 0,
  },
  positive: {
    color: 'success.main',
  },
  negative: {
    color: 'error.main',
  },
  trendIcon: {
    height: '30px',
    width: '30px',
    bgcolor: hexToRgba('#FFFFFF', 0.2),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mb: 1,
  },
  trendDigits: {
    display: 'flex',
    flexDirection: 'column',
    color: 'common.white',
  },
};

const NumberChart: FC<CounterProps> = ({
  value,
  compareTo,
  title,
  history,
  chartConfig,
}) => {
  const [change, setChange] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [positiveTrend, setTrend] = useState(false);
  const isPecentage =
    Boolean(chartConfig?.additionaldata) &&
    chartConfig?.relativeFormat === 'PERCENTAGE';

  useEffect(() => {
    const tempValue = isNaN(value) ? 0 : value;
    if (compareTo || compareTo === 0) {
      const change = tempValue - (compareTo || 0);
      const changePercent = compareTo ? (change / compareTo) * 100 : 0;
      setTrend(change >= 0);
      setChange(change);
      setPercentage(changePercent);
    }
  }, [value, compareTo]);

  const formattingFn = useCallback(
    (val: number, suffix = '') =>
      `${new Intl.NumberFormat('sv-SE', {
        minimumFractionDigits: 0,
      }).format(val)}${suffix}`,
    []
  );

  return (
    <Card
      padding={0}
      sx={{ overflow: 'hidden' }}
      color={chartConfig?.backgroundColor}
    >
      <Box sx={styles.root}>
        {chartConfig?.hidden && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              bgcolor: 'rgba(255, 255, 255, 0.5)',
            }}
          />
        )}
        {history && (
          <Box sx={styles.chart}>
            <AreaChart
              data={history}
              size="MINI"
              color={hexToRgba('#FFFFFF', 0.2)}
            />
          </Box>
        )}

        <Box
          sx={[
            styles.leftContainer,
            !chartConfig?.backgroundColor && { color: 'text.primary' },
          ]}
        >
          <Box component="p" sx={styles.value}>
            <CountUp
              duration={1}
              start={Math.pow(
                10,
                value ? value.toString().split('.')[0].length - 1 : 0
              )}
              end={value}
              formattingFn={(arg) =>
                formattingFn(arg, isPecentage ? ' %' : undefined)
              }
            />
          </Box>
          <Box component="p" sx={styles.title}>
            {title}
          </Box>
        </Box>
        {(Boolean(compareTo) || compareTo === 0) && (
          <Box sx={styles.rightContainer}>
            <Box
              sx={[
                styles.trendIcon,
                Boolean(compareTo || compareTo === 0) &&
                  positiveTrend &&
                  styles.positive,
                Boolean(compareTo) && !positiveTrend && styles.negative,
              ]}
            >
              {Boolean(compareTo || compareTo === 0) && positiveTrend && (
                <MdArrowUpward size="24px" />
              )}
              {Boolean(compareTo) && !positiveTrend && (
                <MdArrowDownward size="24px" />
              )}
            </Box>
            <Box sx={styles.trendDigits}>
              <CountUp
                duration={1}
                start={Math.pow(
                  10,
                  change ? change.toString().split('.')[0].length - 1 : 0
                )}
                end={change}
                formattingFn={formattingFn}
              />

              {compareTo ? (
                <CountUp duration={1} start={0} end={percentage} suffix=" %" />
              ) : (
                <span>-</span>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default NumberChart;
