import { KeysOfUnion } from './typeScriptHelper';
import {
  ChartConfig,
  Mapping,
  PaycodeStats,
  PayslipStats,
  PayslipStatsNumbers,
  StatsNumberProps,
  PropertyStats,
  PropertyStatsNumbers,
  FunctionType,
} from '../stats/stats.model';
import {
  ChartColors,
  ChartData,
  ChartDataSet,
  DrillState,
  Paycode,
} from '../chart/chart.model';
import { DateTime, Interval } from 'luxon';

const PREV_PERIOD_SUFFIX = 'PP';
const SPLY_SUFFIX = 'SPLY';
const CUMUATLIVE_KEY = 'CUMULATIVE';

const roundTwo = (num: number): number =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const getColorBasedOnArt = (str: string) => {
  let hash = 0;
  const modifiedStr = `${str}9922`;
  for (let i = 0; i < modifiedStr.length; i++) {
    hash = modifiedStr.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const paycodeProps: Array<keyof Omit<PaycodeStats, 'id' | 'paycode'>> = [
  'properties',
];
export const statProps: Array<
  keyof Omit<PropertyStats, 'amounts' | 'property'>
> = [
  'average',
  'count',
  'max',
  'median',
  'min',
  'total',
  'distinct_count',
  'averagePerEmployment',
];

export const periodProps: Array<keyof Omit<PayslipStats, 'period' | 'stats'>> =
  ['payslipCount'];
export const statAndPerioddProps: Array<KeysOfUnion<StatsNumberProps>> = [
  'payslipCount',
  'average',
  'count',
  'max',
  'median',
  'min',
  'total',
  'distinct_count',
  'averagePerEmployment',
];

export interface PeriodicDataSetInput {
  paycodes: Paycode[];
  data: PayslipStats[];
  numberOfMonths?: number;
  tooltips?: Array<KeysOfUnion<StatsNumberProps> | ''>;
  config: ChartConfig;
}

export interface PeriodMeta {
  period: string;
  year: string;
  yearNumber: number;
  month: string;
  monthNumber: number;
  PP: string;
  SPLY: string;
}

export const getDataByPropery = (
  dat: PaycodeStats | undefined,
  property: string
): PropertyStats => {
  const a = 0;
  return dat && property && dat.properties
    ? dat.properties
        .filter((d) => d.property == property.toUpperCase())
        .find((x) => true) || ({} as PropertyStats)
    : ({} as PropertyStats);
};

export const getPeriods = (current: string, count = 1): PeriodMeta[] => {
  const currentMoment = DateTime.fromISO(
    `${current.substring(0, 4)}-${current.substring(4, 7)}-01`
  );
  const firstMoment = currentMoment.minus({ months: count - 1 });
  const result: PeriodMeta[] = [];

  [...Array(count)].forEach((_, i) => {
    const temp = firstMoment.plus({ months: i });
    result.push({
      period: temp.toFormat('yyyyMM'),
      year: temp.toFormat('yyyy'),
      yearNumber: temp.year,
      month: temp.toFormat('MMMM'),
      monthNumber: temp.month,
      PP: temp.minus({ months: 1 }).toFormat('yyyyMM'),
      SPLY: temp.minus({ years: 1 }).toFormat('yyyyMM'),
    });
  });

  return result;
};

const getCategoryProperties = ({ data }: { data: ChartData[] }): string[] => {
  if (data.length) {
    const last = data[data.length - 1];

    const temp = Object.keys(last).filter((k) => k !== 'key');
    return temp;
  }
  return [];
};

const getRelativeSuffix = ({
  config,
}: {
  config: ChartConfig;
}): 'SPLY' | 'PP' | null => {
  let suffix: 'SPLY' | 'PP' | null = null;
  if (config.additionaldata === 'YOY') {
    suffix = SPLY_SUFFIX;
  } else if (config.additionaldata === 'PP') {
    suffix = PREV_PERIOD_SUFFIX;
  }

  return suffix;
};

const getRelativeFormatData = ({
  dataset,
  config,
}: {
  dataset: ChartData[];
  config: ChartConfig;
}): ChartData[] => {
  const suffix = getRelativeSuffix({ config });

  return dataset.map((record) => {
    const temp: any = {};
    Object.entries(record).forEach(([key, value]) => {
      if (key === 'key') {
        temp[key] = value;
      }
      if (key !== 'key' && !key.includes(suffix!)) {
        const curr = value || 0;
        const compareTo = record[`${key}__${suffix}`] || 0;

        if (
          config.relativeFormat === 'DIFFERENCE' &&
          typeof curr === 'number' &&
          typeof compareTo === 'number'
        ) {
          temp[key] = curr - compareTo;
        } else if (
          config.relativeFormat === 'PERCENTAGE' &&
          typeof curr === 'number' &&
          typeof compareTo === 'number'
        ) {
          temp[key] = compareTo ? ((curr - compareTo) / compareTo) * 100 : 0;
        }
      }
    });
    return temp;
  });
};

const groupPeriodicData = ({
  data,
  paycodes = [],
  numberOfMonths,
  tooltips = [],
  config: inputConfig,
}: PeriodicDataSetInput): ChartDataSet => {
  const config: ChartConfig = {
    ...inputConfig,
    groupBy: undefined,
  };
  const grouped = data?.reduce((prev, curr) => {
    (prev[curr?.groupBy || ''] = prev[curr?.groupBy || ''] || []).push(curr);
    return prev;
  }, {} as { [key: string]: PayslipStats[] });

  if (config.categoryAxis) {
    const tempArray: ChartDataSet[] = Object.entries(grouped).map(
      ([group, stats]) => {
        const periodData = getPeriodicDataSet({
          data: stats,
          paycodes,
          numberOfMonths,
          tooltips,
          config,
        });
        const last = periodData.dataset[periodData.dataset.length - 1];
        return {
          ...periodData,
          label: `${stats[0].groupByProp}__${stats[0].period}`,
          dataset: [
            {
              ...last,
              key: group || 'TOM',
            },
          ],
        };
      }
    );

    const result = tempArray.reduce(
      (prev, curr) => {
        prev.label = curr.label;
        prev.colors = { ...prev.colors, ...curr.colors };
        prev.dataset.push(curr.dataset[0]);
        prev.properties = Array.from(
          new Set([...prev.properties, ...curr.properties])
        );

        return prev;
      },
      { properties: [], dataset: [], colors: {}, label: '' } as ChartDataSet
    );

    return result;
  }

  const arrayResult: ChartDataSet[] = Object.entries(grouped).map(
    ([group, stats]) => {
      const temp = getPeriodicDataSet({
        data: stats,
        paycodes,
        numberOfMonths,
        tooltips,
        config,
      });
      const properties: string[] = temp.properties.map(
        (p) => `${group || 'TOM'} ${p}`
      );
      const dataset = temp.dataset.map((obj) => {
        const newData = Object.entries(obj).reduce((prev, [key, value]) => {
          if (key === 'key' && value) {
            prev.key = value as string;
          } else {
            prev[`${group || 'TOM'} ${key}`] = value;
          }
          return prev;
        }, {} as ChartData);
        return newData;
      });
      const colors: ChartColors = properties.reduce((prev, p) => {
        prev[p] = getColorBasedOnArt(p);
        return prev;
      }, {} as ChartColors);
      return {
        dataset,
        properties,
        colors,
      };
    }
  );

  const result = arrayResult.reduce(
    (prev, curr) => {
      prev.colors = { ...prev.colors, ...curr.colors };
      prev.properties = [...prev.properties, ...curr.properties];

      if (prev.dataset.length) {
        prev.dataset = prev.dataset.map((val) => {
          const fromCurr = curr.dataset.find((d) => d.key === val.key);

          return {
            ...val,
            ...(fromCurr ?? {}),
          };
        });
      } else {
        prev.dataset = curr.dataset;
      }
      return prev;
    },
    { properties: [], dataset: [], colors: {} } as ChartDataSet
  );

  return result;
};

export const getPeriodicDataSet = ({
  data,
  paycodes = [],
  numberOfMonths,
  tooltips = [],
  config,
}: PeriodicDataSetInput): ChartDataSet => {
  const properties: string[] = [];
  const colors: ChartColors = {};

  if (config.groupBy) {
    return groupPeriodicData({
      data,
      paycodes,
      numberOfMonths,
      tooltips,
      config,
    });
  }

  const suffix = getRelativeSuffix({ config });

  paycodes.forEach((code) => {
    // default keys
    properties.push(code.name);
    colors[code.name] = getColorBasedOnArt(code.name);

    // relative data keys
    if (suffix && !config.relativeFormat) {
      const additionalKey = `${code.name}__${suffix}`;
      properties.push(additionalKey);
      colors[additionalKey] = getColorBasedOnArt(additionalKey);
    }
  });

  //  Cumulative keys
  if (config.cumulative) {
    properties.push(CUMUATLIVE_KEY);
    colors[CUMUATLIVE_KEY] = getColorBasedOnArt(CUMUATLIVE_KEY);

    if (suffix && !config.relativeFormat) {
      const additionalCummulativeKey = `${CUMUATLIVE_KEY}__${suffix}`;
      properties.push(additionalCummulativeKey);
      colors[additionalCummulativeKey] = getColorBasedOnArt(
        additionalCummulativeKey
      );
    }
  }

  const periods =
    data.length > 0
      ? getPeriods(data[data.length - 1].period, numberOfMonths)
      : [];
  const dataset: ChartData[] = [];

  let cumulativeValue = 0;
  let cumulativeTimeRelated = 0;
  periods.forEach((period) => {
    // temp hack until moved to setting
    if (period.monthNumber === 1) {
      cumulativeValue = 0;
      cumulativeTimeRelated = 0;
    }
    const current = data.find((d) => d.period === period.period);
    const temp: ChartData = { key: period.period };
    paycodes.forEach((prop) => {
      if (current) {
        if (statProps.includes(config.key as any)) {
          const list = current.stats
            .filter(
              (s) => s.paycode.startsWith(prop.code) && Boolean(prop.code)
            )
            .map((p) => getDataByPropery(p, config.property));
          let innerKey = config.key as PropertyStatsNumbers;
          if (config.key === 'average') {
            innerKey = 'total';
          }
          temp[prop.name] = list.reduce(
            (prev, next) =>
              prev + (next[innerKey as PropertyStatsNumbers] || 0),
            0
          );

          if (config.key === ('average' as PropertyStatsNumbers)) {
            const total: any = temp[prop.name];
            temp[prop.name] = roundTwo(
              total /
                list.reduce(
                  (prev, next) =>
                    prev + (next['count' as PropertyStatsNumbers] || 0),
                  0
                )
            );
          }
        } else if (periodProps.includes(config.key as any)) {
          temp[prop.name] = current[config.key as PayslipStatsNumbers] || 0;
        } else {
          temp[prop.name] = 0;
        }
        if (config.cumulative) {
          cumulativeValue += temp[prop.name] as number;
        }
      } else {
        temp[prop.name] = 0;
      }
      if (suffix) {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const relativeData = data.find((d) => d.period === period[suffix!]);
        const relativeDataKey = `${prop.name}__${suffix}`;
        /* eslint-enable @typescript-eslint/no-non-null-assertion */

        if (relativeData) {
          if (statProps.includes(config.key as any)) {
            const list = relativeData.stats
              .filter(
                (s) => s.paycode.startsWith(prop.code) && Boolean(prop.code)
              )
              .map((p) => getDataByPropery(p, config.property));
            let innerKey = config.key as PropertyStatsNumbers;
            if (config.key === 'average') {
              innerKey = 'total';
            }
            temp[relativeDataKey] = list.reduce(
              (prev, next) =>
                prev + (next[innerKey as PropertyStatsNumbers] || 0),
              0
            );

            if (config.key === ('average' as PropertyStatsNumbers)) {
              const total: any = temp[relativeDataKey];
              temp[relativeDataKey] = roundTwo(
                total /
                  list.reduce(
                    (prev, next) =>
                      prev + (next['count' as PropertyStatsNumbers] || 0),
                    0
                  )
              );
            }
          } else if (periodProps.includes(config.key as any)) {
            temp[relativeDataKey] =
              relativeData[config.key as PayslipStatsNumbers] || 0;
          }

          if (config.cumulative) {
            cumulativeTimeRelated += temp[relativeDataKey] as number;
          }
        } else {
          temp[relativeDataKey] = 0;
        }
      }
    });

    if (config.cumulative) {
      temp[CUMUATLIVE_KEY] = cumulativeValue;
      if (suffix) {
        const additionalCummulativeKey = `${CUMUATLIVE_KEY}__${suffix}`;
        temp[additionalCummulativeKey] = cumulativeTimeRelated;
      }
    }

    dataset.push(temp);
  });

  let outputDatset = dataset;

  if (suffix && config.relativeFormat) {
    outputDatset = getRelativeFormatData({ dataset, config });
  }

  if (config.difference) {
    let compare = null as any;
    if (config.difference === 'MEAN') {
      const list = dataset.reduce((state: any, item) => {
        Object.entries(item).forEach(([_name, _value]) => {
          if (_name !== 'key') {
            if (state[_name]) state[_name].push(_value);
            else state[_name] = [_value];
          }
        });
        return state;
      }, {});
      compare = Object.entries(list).reduce((state: any, [_name, _list]) => {
        const li = _list as any[];
        li.sort();
        state[_name] = li[li.length / 2];
        return state;
      }, {});
    } else if (
      config.difference === 'AVRAGE' ||
      config.difference === 'AVRAGE_PER_OCCURRENCE'
    ) {
      const isOcc = config.difference === 'AVRAGE_PER_OCCURRENCE';
      let length = {} as any;
      if (isOcc)
        length = dataset.reduce((state: any, item) => {
          Object.entries(item).forEach(
            ([_name, _value]) =>
              (state[_name] = (state[_name] || 0) + (_value !== 0 ? 1 : 0))
          );
          return state;
        }, {});
      const list = dataset.reduce((state: any, item) => {
        const { key, ...rest } = item;
        Object.entries(rest).forEach(
          ([_name, _value]) => (state[_name] = (state[_name] || 0) + _value)
        );
        return state;
      }, {});
      compare = Object.entries(list).reduce((state: any, [_name, _value]) => {
        state[_name] =
          (_value as number) / (isOcc ? length[_name] : dataset.length);
        return state;
      }, {});
    }

    let prevValue = {} as any;
    const prepDataset = dataset.map((next, index) => {
      const newValue = Object.entries(next).reduce(
        (state, [prop, value]: any) => {
          if (prop === 'key') state[prop] = value;
          else if (compare === null) {
            state[prop] = value - (prevValue[prop] || 0);
          } else {
            state[prop] = value - (compare[prop] || 0);
          }
          return state;
        },
        {} as any
      );
      if (config.difference === 'PREVIOUS_WITH_VALUE') {
        Object.entries(next).forEach(([prop, value]) => {
          if (prop !== 'key' && value !== 0) prevValue[prop] = value;
        });
      } else prevValue = next;
      return { ...next, ...newValue } as ChartData;
    }, [] as Array<ChartData>);

    if (
      config.difference === 'MEAN' ||
      config.difference === 'AVRAGE' ||
      config.difference === 'AVRAGE_PER_OCCURRENCE'
    )
      outputDatset = prepDataset;
    else outputDatset = prepDataset.splice(1, prepDataset.length - 1);
  }

  if (config.categoryAxis) {
    return {
      dataset: outputDatset.length
        ? [outputDatset[outputDatset.length - 1]]
        : [],
      colors,
      properties: getCategoryProperties({ data: outputDatset }),
    };
  }

  return {
    dataset: outputDatset,
    properties,
    colors,
    // tooltips: chartTooltips,
    // extra,
  };
};

export interface NumberCardDataInput {
  data: PayslipStats[];
  paycode: Paycode;
  useHistory?: boolean;
  useCompareTo?: boolean;
  config: ChartConfig;
}

export interface NumberCardDataSet {
  value: number;
  compareTo?: number;
  history?: ChartDataSet;
}
export const getNumberCardData = ({
  data,
  paycode,
  useCompareTo,
  config,
}: NumberCardDataInput): NumberCardDataSet => {
  const result: any = {
    value: 0,
  };

  result.history = getPeriodicDataSet({
    data,
    paycodes: [paycode],
    numberOfMonths: 12,
    config,
  });
  const current = result.history.dataset[result.history.dataset.length - 1];
  result.value = current ? current[paycode.name] : 0;

  const compareHistoryData = result.history.dataset || [];
  if (useCompareTo && compareHistoryData.length - 2 > 0) {
    const periodToCompareTo =
      compareHistoryData[compareHistoryData.length - 2].key;
    const toCompareTo = result.history.dataset.find(
      ({ key }: any) => key === periodToCompareTo
    );

    result.compareTo = toCompareTo[paycode.name] || 0;
  }

  return result;
};

export interface GaugeDataSetInput {
  data: PayslipStats[];
  chartConfig: ChartConfig;
}

export const getGaugeData = ({ data, chartConfig }: GaugeDataSetInput) => {
  let totalSum = 0;
  let min = 0;
  let max = 0;
  let value = 0;
  const key = (chartConfig.key as PropertyStatsNumbers) || 'avrage';
  const paycode = chartConfig.paycodePath[0] ?? '/';

  if (data.length > 0) {
    const last = data.slice(-1)[0];
    const lastVal = last.stats
      .filter((x) => x.paycode.startsWith(paycode))
      .map((p) => getDataByPropery(p, chartConfig.property))
      .reduce(
        (prev, next) => prev + (next[key as PropertyStatsNumbers] || 0),
        0
      );

    const rest = data.slice(0, -1);
    rest.forEach((period, i) => {
      const tempValue = period.stats
        .filter((code) => code.paycode.startsWith(paycode))
        .map((p) => getDataByPropery(p, chartConfig.property))
        .reduce(
          (prev, next) => prev + (next[key as PropertyStatsNumbers] || 0),
          0
        );
      totalSum = totalSum + tempValue;
      if (tempValue > max) {
        max = tempValue;
      }
      if (tempValue < min || i === 0) {
        min = tempValue;
      }
    });

    const avrage = totalSum / rest.length;

    // value = lastVal;
    value = normalizeValue({ a: -1, b: 1, min, max, value: lastVal });
  }

  return {
    value,
    min: -1,
    max: 1,
  };
};

export interface ParseChartLevelDataInput {
  chartConfig: ChartConfig;
  mapping: Mapping[];
}

export const parseChartLevelData = ({
  chartConfig,
  mapping,
}: ParseChartLevelDataInput): DrillState => {
  let allPossibleCodes: Mapping[];
  if (chartConfig.sumBySign) {
    //return parseChartLevelDataGroupBy({chartConfig, mapping});
    allPossibleCodes = mapping.filter(
      ({ map }) => !map.startsWith('/AGI/') && !map.startsWith('/Ackumulator/')
    );
  } else {
    const search = chartConfig.paycodePath[0] ?? '/';
    allPossibleCodes = mapping.filter((m) => m.map.startsWith(search));
  }

  if (periodProps.includes(chartConfig.key as PayslipStatsNumbers)) {
    return {
      levels: 1,
      0: {
        name: chartConfig.key,
        paycodes: [{ name: chartConfig.key, code: chartConfig.key }],
      },
      1: {
        name: chartConfig.key,
        paycodes: [{ name: chartConfig.key, code: chartConfig.key }],
      },
      2: {
        name: chartConfig.key,
        paycodes: [{ name: chartConfig.key, code: chartConfig.key }],
      },
      3: {
        name: chartConfig.key,
        paycodes: [{ name: chartConfig.key, code: chartConfig.key }],
      },
    };
  }

  // .map(x => x.map);

  const levels = allPossibleCodes.reduce<DrillState>(
    (prev, next, i) => {
      const [_, zero, one, two, three] = next.map.split('/');

      prev[0] = {
        name: zero,
        paycodes:
          i === 0
            ? [{ name: zero, code: `/${zero}/` }]
            : Array.from(
                new Map(
                  [...prev[0].paycodes, { name: zero, code: `/${zero}/` }].map(
                    (item) => [item.name, item]
                  )
                ).values()
              ),
      };

      prev[1] = {
        name: zero,
        paycodes:
          i === 0
            ? [{ name: one, code: `/${zero}/${one}/` }]
            : Array.from(
                new Map(
                  [
                    ...prev[1].paycodes,
                    { name: one, code: `/${zero}/${one}/` },
                  ].map((item) => [item.name, item])
                ).values()
              ),
      };

      prev[2] = {
        name: two,
        paycodes:
          i === 0
            ? [{ name: two, code: `/${zero}/${one}/${two}/` }]
            : Array.from(
                new Map(
                  [
                    ...prev[2].paycodes,
                    { name: two, code: `/${zero}/${one}/${two}/` },
                  ].map((item) => [item.name, item])
                ).values()
              ),
      };
      prev[3] = {
        name: three,
        paycodes:
          i === 0
            ? [{ name: three, code: `/${zero}/${one}/${two}/${three}` }]
            : Array.from(
                new Map(
                  [
                    ...prev[3].paycodes,
                    { name: three, code: `/${zero}/${one}/${two}/${three}` },
                  ].map((item) => [item.name, item])
                ).values()
              ),
      };

      return prev;
    },
    <DrillState>{ 0: {}, 1: {}, 2: {}, 3: {}, levels: 0 }
  );

  if (chartConfig.sumBySign) {
    levels[1] = levels[3]; // lol nu ballar det ur!
  }
  return levels;
};

export const paycodeToSourceCode = (
  paycodes: string[],
  mapping: Mapping[]
): Paycode[] => {
  return paycodes.reduce<Paycode[]>((prev, current) => {
    const codes = mapping
      .filter((m) => m.map.startsWith(current))
      .map((c) => ({ code: c.paycode, name: c.description }));
    return [...prev, ...codes];
  }, []);
};

export interface NormalizeValueInput {
  a: number;
  b: number;
  max: number;
  min: number;
  value: number;
}

export const normalizeValue = ({
  a,
  b,
  min,
  max,
  value,
}: NormalizeValueInput): number => {
  return ((b - a) * (value - min)) / (max - min) + a;
};

export const forFunc2Type = (func: string | FunctionType): string =>
  (({
    ANALYSIS: 'PAYSLIP',
    BUSINESS: 'PAYSLIP',
    AGI: 'AGI',
  }[(func as string) || 'ANALYSIS'] || (func as string)) as string);

export function* daysFromInterval(interval: Interval) {
  let cursor = interval.start.startOf('day');
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

export function* monthsFromInterval(interval: Interval) {
  let cursor = interval.start;
  while (cursor.startOf('month') <= interval.end.startOf('month')) {
    yield cursor;
    cursor = cursor.plus({ months: 1 });
  }
}

export const getDatasetFromDetails = (
  details: any[],
  period: string,
  property = 'amount',
  useMinMax?: boolean
) => {
  const start = DateTime.fromISO(
    `${period.substr(0, 4)}-${period.substr(4, 2)}-01`
  ).minus({ months: 1 });
  const end = start.endOf('month');
  const alldates = details.map((d) => {
    if (d.from) {
      return DateTime.fromISO(d.from).toSeconds();
    }
    return start.toSeconds();
  });
  let minDate = DateTime.fromSeconds(Math.min(...alldates));
  let maxDate = DateTime.fromSeconds(Math.max(...alldates)).endOf('day');

  if (useMinMax && +start < +minDate) {
    minDate = start;
  }
  if (useMinMax && +end > +maxDate) {
    maxDate = end;
  }

  const monthInterval = Interval.fromDateTimes(minDate, maxDate);
  const dates = Array.from(daysFromInterval(monthInterval)).map((d) =>
    d.toFormat('yyyy-MM-dd')
  );

  const properties: string[] = Array.from(new Set(details.map((d) => d.text)));
  const colors: ChartColors = {};
  properties.forEach((p) => {
    colors[p] = getColorBasedOnArt(p);
  });

  const dayTemplate = properties.reduce((o, key) => ({ ...o, [key]: 0 }), {});
  const dataset: any = details.reduce(
    (arr, curr) => {
      const key = (curr.from ? DateTime.fromISO(curr.from) : start).toFormat(
        'yyyy-MM-dd'
      );
      arr[key][curr.text] =
        arr[key][curr.text] +
        ((property?.toLowerCase() === 'quantity'
          ? 1
          : curr[property.toLowerCase()]) || 0);

      return arr;
    },
    dates.reduce((o, key) => ({ ...o, [key]: { ...dayTemplate } }), {})
  );

  const result = {
    properties,
    dataset: Object.entries<{ [key: string]: object }>(dataset).map(
      ([key, value]) => ({ key, ...value })
    ),
    colors,
  };
  return result;
};
