import { useMediaQuery, useTheme } from '@mui/material';
import { useLottie } from 'lottie-react';
import { FC } from 'react';
import lottieFile from '../../assets/lottie/message_sent.json';

export const AnimMessageSent: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const options = {
    animationData: lottieFile,
    loop: false,
    style: {
      width: 'auto',
      height: theme.spacing(isMobile ? 12 : 20),
    },
  };

  const { View } = useLottie(options);

  return <>{View}</>;
};
