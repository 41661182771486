import { gql } from '@apollo/client';

export const ME_QUERY = gql`
  query Me {
    me {
      id
      firstName
      lastName
      name
      email
      membership {
        id
        memberAt
        role
        status
        addedBy {
          id
          name
        }
        group {
          id
          readOnly
          customerNumber
          name
          logo {
            default
          }
          tenant {
            logo {
              default
            }
            id
            name
          }
          isTenant
          billing {
            id
            isPrivate
            companyName
            orgNo
            email
            reference
            firstName
            lastName
            address
            postalCode
            city
          }
          subscriptions {
            id
            features
            hasActive
            limits {
              users
              employees
              years
            }
          }
          invoices {
            id
            dueDate
            status
          }
          isCourseSubtenant
          isCourseGroup
          isDemoGroup
        }
      }
      showTour {
        value
      }
    }
  }
`;

export const SIGN_UP_MUTATION = gql`
  mutation signUp($user: UserDataInput!, $group: GroupInput!) {
    signUp(user: $user, group: $group) {
      success
      message
    }
  }
`;

export const CREATE_NEW_GROUP_MUTATION = gql`
  mutation createNewGroup($group: GroupInput!) {
    createNewGroup(group: $group) {
      id
      memberAt
      role
      status
      addedBy {
        id
        name
      }
      group {
        id
        customerNumber
        name
        readOnly
        logo {
          default
        }
        tenant {
          logo {
            default
          }
          id
          name
        }
        billing {
          id
          isPrivate
          companyName
          orgNo
          email
          reference
          firstName
          lastName
          address
          postalCode
          city
        }
        subscriptions {
          id
          features
          limits {
            users
            employees
            years
          }
        }
      }
    }
  }
`;

export const ADD_GROUP_TO_TENANT = gql`
  mutation addGroupToTenant($groupId: String!, $name: String!) {
    addGroupToTenant(groupId: $groupId, name: $name) {
      id
      memberAt
      role
      status
      addedBy {
        id
        name
      }
      group {
        id
        customerNumber
        name
        isCourseSubtenant
        isCourseGroup
        isDemoGroup
        couresType
        allowMultipleMembers
        payOwnCost
        readOnly
        logo {
          default
        }
        tenant {
          logo {
            default
          }
          id
          name
        }
        billing {
          id
          isPrivate
          companyName
          orgNo
          email
          reference
          firstName
          lastName
          address
          postalCode
          city
        }
        subscriptions {
          id
          features
          limits {
            users
            employees
            years
          }
        }
      }
    }
  }
`;

export const VALIDATE_ORGANISATION_NUMBER = gql`
  query validateOrganisationNumber($organisationNumber: String) {
    validateOrganisationNumber(organisationNumber: $organisationNumber) {
      success
      message
    }
  }
`;

export const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail($id: String!, $email: String!) {
    verifyEmail(id: $id, email: $email) {
      success
      message
    }
  }
`;

export const COMPLETE_SIGN_UP_MUTATION = gql`
  mutation completeSignUp($user: UserDataInput!) {
    completeSignUp(input: $user) {
      success
      message
    }
  }
`;

export const VERIFY_INVITATION_MUTATION = gql`
  mutation verifyInvitation($userId: String!, $groupId: String!) {
    verifyInvitation(userId: $userId, groupId: $groupId) {
      success
      message
    }
  }
`;

export const USER_SHOW_TOUR_MUTATION = gql`
  mutation userShowTour($show: Boolean) {
    userShowTour(show: $show) {
      showTour {
        value
      }
    }
  }
`;
