import fetch from "cross-fetch";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

export const client = new ApolloClient({
  link: new HttpLink({
    // uri: 'http://localhost:4000/dev/graphql',
    uri: process.env.GATSBY_GRAPH_API_URL,
    fetch,
  }),
  cache: new InMemoryCache(),
});
