import { gql } from '@apollo/client';

export const PAY_PULSE_QUERY = gql`
  query payPulse($groupId: ID, $period: String) {
    payPulse(groupId: $groupId, period: $period) {
      user {
        id
        year 
        month
        days {
          day
          rating
          distribution {
            rating
            part
          }
        }
      }
      group {
        id
        year 
        month
        days {
          day
          rating
          distribution {
            rating
            part
          }
        }
      }
      benchmark {
        id
        year 
        month
        days {
          day
          rating
          distribution {
            rating
            part
          }
        }
      }
    }
  }
`;

export const PAY_PULSE_RATING_MUTATION = gql`
  mutation reportRating($rating: Int!, $day: String) {
    reportRating(rating: $rating, day: $day) {
      id
      year
      month
      days {
        day
        rating
        distribution {
            rating
            part
          }
      }
    }
  }
`;
