import {
  Children,
  FC,
  ReactNode,
  createElement,
  isValidElement,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import ConfirmButton from '../../ui/Button/ConfirmButton';

// function flatten(data: any, c?: string) {
//   const result = {};
//   for (const i in data) {
//     if (typeof data[i] == 'object')
//       Object.assign(result, flatten(data[i], c + '.' + i));
//     else result[(c + '.' + i).replace(/^\./, '')] = data[i];
//   }
//   return result;
// }

const fieldError = (errors: any, name: string) => {
  const names = name.split('.');

  const result = names.reduce((acc, curr) => {
    const error = acc[curr];
    if (error) {
      return error;
    }
    return acc;
  }, errors);

  return result;
};

export interface FormProps {
  children: ReactNode;
  schema?: any;
  onSubmit?: (values: any) => void;
  defaultValues?: any;
  values: any;
  onSubmitInvalid?: (values: any) => void;
  onChange?: (values: any) => void;
  onFormStateChange?: (isValid: boolean, isDirty: boolean) => void;
  loading?: boolean;
  readOnly?: boolean | null;
  onDelete?: () => void;
  onCancel?: () => void;
  deleting?: boolean;
  confirmMessage?: string;
}

export const Form: FC<FormProps> = ({
  defaultValues,
  values,
  children,
  onSubmit,
  onCancel,
  schema,
  onSubmitInvalid = () => ({}),
  onChange,
  onFormStateChange,
  loading,
  deleting,
  readOnly,
  onDelete,
  confirmMessage = 'Är du säker?',
}) => {
  const {
    handleSubmit,
    control,
    register,
    unregister,
    watch,
    reset,
    formState: { errors, isDirty, isValid },
    setValue,
  } = useForm({
    values,
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
    mode: 'onChange',
  });

  useEffect(() => {
    if (onChange) {
      const subscription = watch((values) => {
        onChange(values);
      });

      return () => subscription.unsubscribe();
    }
  }, [watch, onChange]);

  useEffect(() => {
    if (onFormStateChange) {
      onFormStateChange(isValid, isDirty);
    }
  }, [onFormStateChange, isValid, isDirty]);

  const createChildren = (_children: ReactNode): any =>
    Children.map(_children, (child) => {
      if (isValidElement(child) && typeof child === 'object') {
        let props = child.props;
        if (child?.props?.name) {
          props = {
            ...props,
            readOnly,
            register,
            unregister,
            control,
            setValue,
            key: child.props.name,
            error: fieldError(errors, child.props.name),
          };
        } else if (Children.count(child) > 0) {
          props = {
            ...props,
            children: createChildren((child?.props as any)?.children),
          };
        }

        return createElement(child.type, props);
      }

      return child;
    });

  return (
    <>
      <Box
      // sx={{
      //   overflowY: 'auto',
      // }}
      >
        {createChildren(children)}
        {!readOnly && onSubmit && (
          <>
            <Box
              sx={{
                zIndex: 3,
                display: 'flex',
                // flexDirection: smallScreen ? 'column-reverse' : 'row',
                flexDirection: 'row',
                gap: 2,
                pt: 2,
                justifyContent: 'flex-end',
                position: isDirty ? 'sticky' : 'inherit',
                bottom: 0,
                bgcolor: isDirty ? 'background.paper' : undefined,
              }}
            >
              {onCancel && (
                <Button size="small" variant="outlined" onClick={onCancel}>
                  Avbryt
                </Button>
              )}

              <Button
                size="small"
                variant="outlined"
                color={!isDirty || loading ? 'inherit' : 'primary'}
                disabled={!isDirty || loading || deleting}
                onClick={() => reset()}
              >
                Ångra
              </Button>

              <Button
                size="small"
                variant="contained"
                color={!isDirty || loading ? 'inherit' : 'primary'}
                disabled={!isDirty || loading || deleting}
                onClick={() => handleSubmit(onSubmit, onSubmitInvalid)()}
              >
                Spara
              </Button>

              {/* <SplitButton
                              color={!isDirty ? 'neutral' : 'primary'}
                              disabled={!isDirty || deleting}
                              loading={loading}
                              onClick={(e, val) => handleSubmit(
                                  (values: any[]) => onSubmit(values, val),
                                  onSubmitInvalid
                              )()}
                              options={submitOptions}
                          /> */}
            </Box>
            {onDelete && (
              <ConfirmButton
                message={confirmMessage}
                onConfirm={onDelete}
                component={
                  <Button
                    fullWidth
                    color="error"
                    disabled={loading}
                    sx={{
                      my: 2,
                    }}
                  >
                    Radera
                  </Button>
                }
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};
