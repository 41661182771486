import { gql } from '@apollo/client';

export const SUBSCRIPTION_COST_FRAGMENT = gql`
  fragment SubscriptionCostFragment on ServiceSubscription {
    cost {
      annual {
        users
        employees
        years
        price
        interval
        renew
      }
      monthly {
        users
        employees
        years
        price
        interval
        renew
      }
      custom {
        users
        employees
        years
        price
        interval
        renew
      }
    }
  }
`;

export const SUBSCRIPTIONS_QUERY = gql`
  ${SUBSCRIPTION_COST_FRAGMENT}
  query subscriptions($offer: String) {
    subscriptions(offer: $offer) {
      id
      name
      extra
      course
      propertys {
        key
        value
      }
      ...SubscriptionCostFragment
      features
    }
  }
`;

export const SUBSCRIPTION_QUERY = gql`
  query subscription($id: String!, $promoCode: String) {
    subscription(id: $id, promoCode: $promoCode) {
      base {
        id
        name
        extra
        cost {
          monthly {
            user
            data
            interval
            renew
          }
          annual {
            user
            data
            interval
            renew
          }
          custom {
            user
            data
            interval
            renew
          }
        }
      }
      promotion {
        id
        name
        extra
        cost {
          monthly {
            user
            data
            interval
            renew
          }
          annual {
            user
            data
            interval
            renew
          }
          custom {
            user
            data
            interval
            renew
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTION_PRICE = gql`
  query subscriptionPrice(
    $id: String!
    $billing: String!
    $promoCode: String
    $extra: [String!]!
  ) {
    subscriptionPrice(
      id: $id
      billing: $billing
      promoCode: $promoCode
      extra: $extra
    ) {
      interval
      renew
      users
      employees
      years
      discount
      subtotal
      extra {
        id
        users
        employees
        discount
        subtotal
      }
      totalNet
      totalVat
      total
      totalDiscount
      isPrivate
    }
  }
`;
