import React, { useEffect } from 'react';
import type {
  DraggableAttributes,
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import {
  Box,
  ButtonGroup,
  Card,
  Chip,
  IconButton,
  keyframes,
} from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { MdDragHandle } from 'react-icons/md';

const fadeInFrame = keyframes`
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
`;
export type Item = { id: UniqueIdentifier; value?: React.ReactNode };

export interface TaskBoardItemProps {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  attributes?: DraggableAttributes;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  minimal?: boolean;
  onRemove?(): void;
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: TaskBoardItemProps['transform'];
    transition: TaskBoardItemProps['transition'];
    value: TaskBoardItemProps['value'];
  }): React.ReactElement;
}

export const TaskBoardItem = React.memo(
  React.forwardRef<HTMLLIElement, TaskBoardItemProps>(
    (
      {
        color,
        dragOverlay = false,
        dragging,
        disabled,
        fadeIn = false,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        minimal = false,
        attributes,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      if (minimal) {
        return (
          <Box
            {...attributes}
            {...listeners}
            {...props}
            style={
              {
                ...wrapperStyle,
                transition: [transition, wrapperStyle?.transition]
                  .filter(Boolean)
                  .join(', '),
                '--translate-x': transform
                  ? `${Math.round(transform.x)}px`
                  : undefined,
                '--translate-y': transform
                  ? `${Math.round(transform.y)}px`
                  : undefined,
                '--scale-x': transform?.scaleX
                  ? `${transform.scaleX}`
                  : undefined,
                '--scale-y': transform?.scaleY
                  ? `${transform.scaleY}`
                  : undefined,
                '--index': index,
                '--color': color,
              } as React.CSSProperties
            }
            sx={[
              {
                width: 1,
                display: 'flex',
                boxSizing: 'border-box',
                transform:
                  'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))',
                transformOrigin: '0 0',
                touchAction: 'manipulation',
              },
              fadeIn && {
                animation: `${fadeInFrame} 500ms ease`,
              },
              dragOverlay && {
                '--scale': 1.05,
                '--box-shadow':
                  '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)',
                '--box-shadow-picked-up':
                  '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)',
                zIndex: 2000,
              },
            ]}
            ref={ref}
          >
            <Chip
              color="primary"
              sx={[
                {
                  width: 1,

                  // '&:hover': {
                  //   bgcolor: 'red',
                  // },
                },
                fadeIn && {
                  animation: `${fadeInFrame} 500ms ease`,
                },
                dragOverlay && {
                  cursor: 'inherit',
                  animation: 'pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)',
                  transform: ' scale(var(--scale))',
                  boxShadow: 'var(--box-shadow-picked-up)',
                  opacity: 1,
                },
              ]}
              label={value as string}
            />
          </Box>
        );
      }

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <Card
          {...listeners}
          sx={[
            {
              border: '1px solid',
              borderColor: 'border',
              display: 'flex',
              boxSizing: 'border-box',
              transform:
                'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))',
              transformOrigin: '0 0',
              touchAction: 'manipulation',
            },
            fadeIn && {
              animation: `${fadeInFrame} 500ms ease`,
            },
            dragOverlay && {
              cursor: 'inherit',
              animation: 'pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)',
              transform: ' scale(var(--scale))',
              boxShadow: 'var(--box-shadow-picked-up)',
              opacity: 1,
            },
          ]}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(', '),
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              '--color': color,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <Box
            sx={[
              {
                display: 'flex',
                boxSizing: 'border-box',
                transform:
                  'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))',
                transformOrigin: '0 0',
                touchAction: 'manipulation',
              },
              Boolean(dragging) &&
                !dragOverlay && {
                  opacity: 'var(--dragging-opacity, 0.5)',
                  zIndex: 0,
                  '&:focus': {
                    boxShadow: 'var(--box-shadow-picked-up)',
                  },
                },
              fadeIn && {
                animation: `${fadeInFrame} 500ms ease`,
              },
              dragOverlay && {
                '--scale': 1.05,
                '--box-shadow':
                  '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)',
                '--box-shadow-picked-up':
                  '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)',
                zIndex: 999,
              },
            ]}
            // className={classNames(
            //   styles.Item,
            //   dragging && styles.dragging,
            //   handle && styles.withHandle,
            //   dragOverlay && styles.dragOverlay,
            //   disabled && styles.disabled,
            //   color && styles.color
            // )}
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {value}
            {(Boolean(onRemove) || Boolean(handle)) && (
              <ButtonGroup
                sx={{
                  '&:hover': {
                    '& .lane-delete-button': {
                      display: 'block',
                    },
                  },
                }}
              >
                {Boolean(onRemove) && (
                  <IconButton
                    sx={{ display: 'none' }}
                    className="lane-delete-button"
                    onClick={onRemove}
                  >
                    <IoClose />
                  </IconButton>
                )}
                {Boolean(handle) && (
                  <IconButton {...handleProps} {...listeners}>
                    <MdDragHandle />
                  </IconButton>
                )}
              </ButtonGroup>
            )}
          </Box>
        </Card>
      );
    }
  )
);
