import { ThemeOptions } from '@mui/material';

export const muiThemeOverrides: ThemeOptions = {
  typography: {
    fontFamily: 'Nunito Sans, Helvetica, Arial, sans-serif',
    h1: {
      fontFamily: 'Montserrat Alternates',
    },
    h2: {
      fontFamily: 'Montserrat Alternates',
    },
    h3: {
      fontFamily: 'Montserrat Alternates',
    },
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 600,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
      },
    },
    MuiDialog: {
      defaultProps: {
        scroll: 'paper',
        BackdropProps: {
          sx: {
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(1px)',
          },
        },
      },
      styleOverrides: {
        paper: {
          borderTopLeftRadius: 'unset',
          borderBottomRightRadius: 'unset',
          borderTopRightRadius: '40px',
          borderBottomLeftRadius: '40px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingBottom: '20px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: '99em',
        },
      },
    },
  },
};
