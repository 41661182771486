import {
  Box,
  Card,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  FC,
  ReactElement,
  KeyboardEvent,
  ChangeEvent,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  IoArrowDownOutline,
  IoArrowUpOutline,
  IoSearchOutline,
} from 'react-icons/io5';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useTranslation } from 'react-i18next';

export interface SearchItem {
  id: string;
  idFor?: string;
  title: {
    text: string;
    category?: string;
  };
  subtitle?: {
    text: string;
    category?: string;
  };
  filters?: string[];
  icon?: ReactElement;
  [key: string]: any;
}

export interface Props {
  options?: SearchItem[];
  placeholder?: string;
  multiple?: boolean;
  autocomplete?: boolean;
  onChange?: (inputValue: string) => void;
  onSelect?: (selectedItems: SearchItem[]) => void;
  loading?: boolean;
  quickSelect?: boolean;
  hideSubtitle?: boolean;
  disableShadows?: boolean;
  sx?: SxProps;
  defaultValues?: string[];
  canDelete?: boolean;
}

const SearchBar: FC<Props> = ({
  options = [],
  placeholder,
  multiple,
  autocomplete,
  onSelect,
  onChange,
  loading,
  quickSelect,
  hideSubtitle,
  disableShadows = false,
  defaultValues,
  canDelete = true,
  sx = {},
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [focused, setFocused] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SearchItem[]>([]);
  const inputEl = useRef<HTMLInputElement>(null);
  const [prevItem, setPrevItem] = useState<SearchItem>();
  const [nextItem, setNextItem] = useState<SearchItem>();
  const { t } = useTranslation();

  useEffect(() => {
    if (options?.length) {
      if (selectedItems.length && quickSelect) {
        const currentIndex = options.findIndex(
          (option) => option.id === selectedItems[0]?.id
        );
        setPrevItem(options?.[currentIndex - 1]);
        setNextItem(options?.[currentIndex + 1]);
      } else {
        setNextItem(options[0]);
      }
    }
  }, [options, quickSelect, selectedItems]);

  useEffect(() => {
    if (options?.length && defaultValues?.length && !selectedItems.length) {
      const preSelected = options.filter((o) =>
        defaultValues.some((d) => d === o.id)
      );

      if (preSelected.length) {
        if (multiple) {
          setSelectedItems((curr) => {
            if (curr?.length || !preSelected) return curr;

            return preSelected;
          });
        } else {
          setSelectedItems([preSelected[0]]);
        }
      }
    }
  }, [defaultValues, options, selectedItems, multiple]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleSelect = (item: SearchItem | undefined) => {
    if (!item) return;

    const itemToUse = item.idFor
      ? options.find((o) => o.id === item.idFor) || item
      : item;

    if (selectedItems.map((s) => s.id).includes(item.id)) return;

    if (multiple) {
      if (onSelect) {
        onSelect([...selectedItems, itemToUse]);
      }
      setSelectedItems((oldItems) => [...oldItems, itemToUse]);
      return;
    }
    if (onSelect) {
      onSelect([itemToUse]);
    }
    setSelectedItems([itemToUse]);
    setFocused(false);
    setSearchValue('');
  };

  const handleKeypress = (key: KeyboardEvent) => {
    if (key.key === 'Escape') {
      inputEl.current?.blur();
      setFocused(false);
      setSearchValue('');
    }
  };

  const handleDelete = (item: SearchItem) => {
    const newItems = selectedItems.filter((i) => i.title !== item.title);
    setSelectedItems(newItems);
    if (onSelect) {
      onSelect(newItems);
    }
  };

  return (
    <Box
      sx={[
        {
          position: 'relative',
          flex: 1,
          height: '36px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <ClickAwayListener onClickAway={() => setFocused(false)}>
        <Card
          raised={focused && !disableShadows}
          sx={[
            {
              position: 'absolute',
              display: 'flex',
              flex: 1,
              px: 1,
              pl: selectedItems.length ? 0.5 : undefined,
              borderRadius: '18px',
              alignItems: 'center',
              right: 0,
              left: 0,
              flexDirection: 'column',
            },
            focused && {
              zIndex: 1,
            },
            disableShadows && {
              border: 'solid 1px',
              borderColor: 'border',
              boxShadow: 'unset',
            },
          ]}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 1,
              height: 36,
            }}
          >
            {!selectedItems.length && <IoSearchOutline fontSize="20" />}

            {selectedItems.map((selectedItem, i) => (
              <Chip
                variant="outlined"
                key={selectedItem.id}
                label={selectedItem.title.text}
                onDelete={
                  canDelete ? () => handleDelete(selectedItem) : undefined
                }
                sx={[
                  Boolean(i) && {
                    ml: 0.5,
                  },
                ]}
              />
            ))}

            <Box
              component="input"
              placeholder={placeholder || t('SEARCH')}
              disabled={loading}
              ref={inputEl}
              value={searchValue}
              onChange={handleChange}
              onFocus={() => setFocused(true)}
              onKeyUp={handleKeypress}
              sx={{
                ml: 1,
                backgroundColor: 'transparent',
                border: 'none',
                outline: 'none',
                fontSize: '16px',
                flex: 1,
                color: 'text.primary',
                '&::placeholder': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: 'text.secondary',
                },
              }}
            />
            {loading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={20} />
              </Box>
            )}
            {quickSelect && !loading && (
              <Box display="flex">
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <Tooltip title={prevItem?.title.text || ''}>
                  <IconButton
                    size="small"
                    disabled={!prevItem}
                    onClick={() => handleSelect(prevItem)}
                  >
                    <IoArrowUpOutline />
                  </IconButton>
                </Tooltip>

                <Tooltip title={nextItem?.title.text || ''}>
                  <IconButton
                    size="small"
                    disabled={!nextItem}
                    onClick={() => handleSelect(nextItem)}
                  >
                    <IoArrowDownOutline />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          {focused && autocomplete && (
            <Box
              sx={{
                width: 1,
              }}
            >
              {/* <Divider variant="middle" /> */}

              <Box
                sx={{
                  maxHeight: '400px',
                  overflowY: 'auto',
                }}
              >
                <List dense>
                  {options
                    .filter((f) => {
                      const matchesFilter = f?.filters?.some((af) =>
                        af.toLowerCase().includes(searchValue.toLowerCase())
                      );

                      return (
                        f.title.text
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        f.subtitle?.text
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        matchesFilter
                      );
                    })
                    .map((option, i) => {
                      const matchesTitle = match(
                        option.title.text,
                        searchValue
                      );
                      const partsTitle = parse(option.title.text, matchesTitle);

                      const matchesSubtitle = option.subtitle
                        ? match(option.subtitle.text, searchValue)
                        : [];
                      const partsSubtitle = parse(
                        option.subtitle?.text || '',
                        matchesSubtitle
                      );

                      return (
                        <ListItemButton
                          dense
                          key={option.id}
                          onClick={() => handleSelect(option)}
                          selected={selectedItems
                            .map((s) => s.id)
                            .includes(option.id)}
                        >
                          {option.icon && (
                            <ListItemIcon sx={{ minWidth: '32px' }}>
                              {option.icon}
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={
                              <Typography noWrap>
                                {partsTitle.map((part: any, index: number) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                                {option.title.category ? (
                                  <span style={{ fontStyle: 'italic' }}>
                                    {` - ${option.title?.category}`}
                                  </span>
                                ) : null}
                              </Typography>
                            }
                            secondary={
                              !hideSubtitle && [
                                ...partsSubtitle.map(
                                  (part: any, index: number) => (
                                    <span
                                      key={index}
                                      style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  )
                                ),
                                option.subtitle?.category ? (
                                  <span
                                    key={`${option.subtitle.category}-${i}`}
                                    style={{ fontStyle: 'italic' }}
                                  >{` - ${option.subtitle?.category}`}</span>
                                ) : null,
                              ]
                            }
                          />
                        </ListItemButton>
                      );
                    })}
                </List>
              </Box>
            </Box>
          )}
        </Card>
      </ClickAwayListener>
    </Box>
  );
};

export default SearchBar;
