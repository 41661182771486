declare module '@mui/material/styles' {
  interface OtherColor {
    greenContrast: React.CSSProperties['color'];
    emptyChartFill: React.CSSProperties['color'];
  }

  interface Palette {
    border: string;
    other: OtherColor;
  }

  interface PaletteOptions {
    border: string;
    other: OtherColor;
  }
}

export enum ThemeVariant {
  dark = 'dark',
  light = 'light',
}
