import { gql } from '@apollo/client';

export const USERS_QUERY = gql`
  query Users {
    users {
      id
      externalId
      firstName
      lastName
      name
      email
      membership {
        id
        memberAt
        role
        status
        addedBy {
          id
          name
        }
        group {
          id
          name
        }
      }
    }
  }
`;
