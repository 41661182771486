import {
  FC,
  ReactElement,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { FormItemProps } from './form.model';
import {
  Box,
  FormHelperText,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Controller } from 'react-hook-form';

export type InputBaseProps = FormInputProps & {
  preview?: ReactNode | null;
};

export const Input: FC<InputBaseProps> = forwardRef(
  (
    {
      groupLabel,
      label,
      error,
      setValue,
      preview,
      previewType = 'regular',
      sx = {},
      InputProps,
      type,
      select,
      value,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [showPw, togglePw] = useState(false);

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault();
    };

    const pwAdornnment = (): ReactElement<any> => (
      <InputAdornment position="end">
        <IconButton
          onClick={() => togglePw(!showPw)}
          onMouseDown={handleMouseDownPassword}
          size="large"
        >
          {showPw ? <MdVisibility /> : <MdVisibilityOff />}
        </IconButton>
      </InputAdornment>
    );

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {/* {label ? <FormLabel>{`${label}`}</FormLabel> : <div />} */}
          {preview && previewType === 'regular' && (
            <FormHelperText>{preview}</FormHelperText>
          )}
        </Box>
        <Tooltip
          arrow
          placement="top"
          title={<FormHelperText>{preview}</FormHelperText>}
          open={Boolean(preview) && previewType === 'tooltip'}
        >
          <TextField
            size="small"
            variant="outlined"
            select={select}
            label={label ? t(label) : undefined}
            value={value}
            defaultValue={select ? value : undefined}
            error={Boolean(error?.message)}
            type={type !== 'password' ? type : showPw ? 'text' : type}
            InputProps={{
              ref,
              ...InputProps,
              endAdornment: (
                <>
                  {preview && previewType === 'inline' && (
                    <FormHelperText
                      sx={{
                        mt: 0,
                      }}
                    >
                      {preview}
                    </FormHelperText>
                  )}
                  {InputProps?.endAdornment}
                </>
              ),
              ...(type === 'password' && {
                endAdornment: pwAdornnment(),
              }),
            }}
            {...{
              ...rest,
              helperText: error?.message ? error.message : rest.helperText,
            }}
            sx={[
              {
                '--Input-decorator-childHeight': '30px',
              },
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
          />
        </Tooltip>
      </>
    );
  }
);

export type FormInputProps = FormItemProps;

export const FormInput: FC<FormInputProps> = ({
  register,
  unregister,
  control,
  name,
  ...rest
}) => {
  useEffect(() => {
    if (unregister && !name.includes('.')) {
      return () =>
        unregister(name, {
          keepDefaultValue: true,
          keepValue: true,
          keepIsValid: true,
        });
    }
  }, [name, unregister]);

  if (!register) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <Input {...rest} {...field} />}
    />
  );
};
