import { gql } from '@apollo/client';

export const BILLING_ON_GROUP_FRAGMENT = `
  fragment GroupTotalFragment on Group {
    id
    customerNumber
    name
    isCourseSubtenant
    isCourseGroup
    isDemoGroup
    couresType
    allowMultipleMembers
    payOwnCost
    readOnly
    billing {
      id
      isPrivate
      companyName
      orgNo
      email
      reference
      firstName
      lastName
      address
      postalCode
      city
    }
    tenant {
      id
      name
    }
    members {
      id
      role
      memberAt
      status
      addedBy {
        id
        name
      }
      user {
        id
        name
        email
      }
    }
    invoices {
      id
      invoiceNumber
      invoiceDate
      invoicePeriodStart
      invoicePeriodEnd
      dueDate
      paymentDate
      status
      amount
      amountNet
      vat
      discount
      currency
      items {
        start
        end
        description
        amount
        amountNet
      }
      billing {
        id
        isPrivate
        companyName
        orgNo
        email
        reference
        firstName
        lastName
        address
        postalCode
        city
      }
    }
    subscriptions {
      id
      hasActive
      features
      limits {
        users
        employees
        years
      }
      subscriptions(history: true) {
        id
        planId
        name
        start
        end
        isActive
        extra
        features
        price {
          interval
          renew
          users
          employees
          years
          discount
          subtotal
          totalNet
          totalVat
          total
          totalDiscount
          isPrivate
        }
      }
    }
  }
`;

export const GET_GROUP = gql`
  ${BILLING_ON_GROUP_FRAGMENT}
  query GetGroup($id: String!) {
    group(id: $id) {
      ...GroupTotalFragment
    }
  }
`;

export const CREATE_GROUP = gql`
  ${BILLING_ON_GROUP_FRAGMENT}
  mutation CreateGroup($name: String!) {
    createGroup(name: $name) {
      ...GroupTotalFragment
    }
  }
`;

export const UPDATE_GROUP = gql`
  ${BILLING_ON_GROUP_FRAGMENT}
  mutation UpdateGroup(
    $id: ID!
    $name: String!
    $billing: BillingInformationInput
  ) {
    updateGroup(id: $id, name: $name, billing: $billing) {
      ...GroupTotalFragment
    }
  }
`;

export const CHANGE_ROLE = gql`
  mutation ChangeRole($groupId: String!, $userId: String!, $role: UserRole!) {
    changeRole(groupId: $groupId, userId: $userId, role: $role) {
      id
      role
      memberAt
      status
      user {
        id
        name
        email
      }
    }
  }
`;
export const INVITE_MEMBER = gql`
  mutation InviteMember(
    $groupId: String!
    $userId: String
    $email: String
    $role: UserRole!
  ) {
    inviteMember(
      groupId: $groupId
      userId: $userId
      email: $email
      role: $role
    ) {
      id
      role
      memberAt
      status
      user {
        id
        name
        email
      }
    }
  }
`;

export const REMOVE_MEMBER = gql`
  mutation RemoveMember($groupId: String!, $userId: String!) {
    removeMember(groupId: $groupId, userId: $userId) {
      id
    }
  }
`;

export const INVITE_REPLY = gql`
  mutation InviteReply($groupId: String!, $accept: Boolean!) {
    inviteReply(groupId: $groupId, accept: $accept) {
      id
    }
  }
`;

export const VALIDATE_ORG_NO = gql`
  mutation validateOrgNo($orgNo: String) {
    validateOrgNo(orgNo: $orgNo)
  }
`;

export const SUB_GROUPS_QUERY = gql`
  query SubGroups($id: String!) {
    subGroups(id: $id) {
      id
      name
    }
  }
`;
