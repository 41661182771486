import { gql } from '@apollo/client';

export const DASHBOARD_FRAGMENT = gql`
  fragment DashboardFragment on Dashboard {
    id
    standard
    order
    hidden
    locales {
      language
      title
    }
  }
`;

export const DASHBOARDS_QUERY = gql`
  ${DASHBOARD_FRAGMENT}
  query dashboards($groupId: ID!, $forFunction: String!) {
    dashboards(groupId: $groupId, forFunction: $forFunction) {
      ...DashboardFragment
    }
  }
`;
export const UPDATE_DASHBOARD = gql`
  ${DASHBOARD_FRAGMENT}
  mutation updateDashboard(
    $groupId: String!
    $forFunction: String!
    $data: DashboardInput!
  ) {
    updateDashboard(groupId: $groupId, forFunction: $forFunction, data: $data) {
      ...DashboardFragment
    }
  }
`;

export const CREATE_DASHBOARD = gql`
  ${DASHBOARD_FRAGMENT}
  mutation createDashboard(
    $groupId: String!
    $forFunction: String!
    $data: DashboardInput!
  ) {
    createDashboard(groupId: $groupId, forFunction: $forFunction, data: $data) {
      ...DashboardFragment
    }
  }
`;

export const COPY_DASHBOARD = gql`
  ${DASHBOARD_FRAGMENT}
  mutation copyDashboard(
    $groupId: String!
    $forFunction: String!
    $id: ID!
    $fromStandard: Boolean
  ) {
    copyDashboard(
      groupId: $groupId
      forFunction: $forFunction
      id: $id
      fromStandard: $fromStandard
    ) {
      ...DashboardFragment
    }
  }
`;

export const DELETE_DASHBOARD = gql`
  mutation deleteDashboard($groupId: String!, $id: ID!, $forFunction: String!) {
    deleteDashboard(groupId: $groupId, id: $id, forFunction: $forFunction) {
      success
      message
    }
  }
`;

export const DASHBOARD_CONFIG_QUERY = gql`
  query dashboardConfig($groupId: ID!, $forFunction: String!) {
    dashboardConfig(groupId: $groupId, forFunction: $forFunction) {
      id
      hidden
      filters {
        property
      }
    }
  }
`;

export const UPDATE_DASHBOARD_CONFIG = gql`
  mutation updateDashboardConfig(
    $groupId: ID!
    $forFunction: String!
    $data: DashboardConfigInput!
  ) {
    updateDashboardConfig(
      groupId: $groupId
      forFunction: $forFunction
      data: $data
    ) {
      id
      hidden
      filters {
        property
      }
    }
  }
`;

export const CHART_FRAGMENT = gql`
  fragment ChartFragment on Chart {
    id
    title
    chart
    key
    allowDrill
    initalDrill
    drill {
      reference {
        type
        variant
      }
      local {
        key
        order
      }
    }
    backgroundColor
    property
    difference
    relativeFormat
    supportLines {
      min
      max
      average
      mean
      trend
      budgetDiff
      budgetDiffPercentage
    }
    additionaldata
    groupBy
    sumBySign
    divideBy
    hidden
    locales {
      language
      title
    }
    paycodePath
    layout {
      lg {
        x
        y
        w
        h
      }
    }
    monthCount
    categoryAxis
    cumulative
    showNextLevelDetails
  }
`;

export const CHART_LAYOUT_QUERY = gql`
  ${CHART_FRAGMENT}
  query layout($groupId: ID!, $dashboardId: ID!, $standard: Boolean!) {
    layout(groupId: $groupId, dashboardId: $dashboardId, standard: $standard) {
      ...ChartFragment
    }
  }
`;

export const CREATE_CHART = gql`
  ${CHART_FRAGMENT}
  mutation createChart(
    $groupId: String!
    $data: ChartInput!
    $dashboardId: ID!
  ) {
    createChart(groupId: $groupId, data: $data, dashboardId: $dashboardId) {
      ...ChartFragment
    }
  }
`;

export const UPDATE_CHART = gql`
  ${CHART_FRAGMENT}
  mutation updateChart(
    $groupId: String!
    $data: ChartInput!
    $dashboardId: ID!
  ) {
    updateChart(groupId: $groupId, data: $data, dashboardId: $dashboardId) {
      ...ChartFragment
    }
  }
`;
export const DELETE_CHART = gql`
  mutation deleteChart($groupId: String!, $id: ID!, $dashboardId: ID!) {
    deleteChart(groupId: $groupId, id: $id, dashboardId: $dashboardId) {
      success
      message
    }
  }
`;
