import { AvatarProps as MuiProps, Avatar as MuiAvatar } from '@mui/material';
import { FC } from 'react';

export interface AvatarProps extends MuiProps {
  size?: 'small' | 'medium';
}

const Avatar: FC<AvatarProps> = ({
  children,
  size = 'medium',
  color,
  ...restProps
}) => {
  return (
    <MuiAvatar
      sx={[
        size === 'small' && {
          height: 32,
          width: 32,
          fontSize: '16px',
          bgcolor: color ? color : 'secondary.main',
          color: 'common.white',
        },
      ]}
      {...restProps}
    >
      {children}
    </MuiAvatar>
  );
};

export default Avatar;
