import { FC, ReactElement, ReactNode, forwardRef } from 'react';
import { AppBar, Toolbar, Container, Box, SxProps } from '@mui/material';

export interface NavbarProps {
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  navItems: ReactElement[] | ReactElement;
  hideNavItemsOn?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  linkComponent?: ReactElement;
  rightItems?: ReactNode;
  logo?: ReactElement;
  sx?: SxProps;
}

const Navbar: FC<NavbarProps> = forwardRef<HTMLDivElement, NavbarProps>(
  (
    {
      maxWidth = 'md',
      navItems,
      hideNavItemsOn = 'sm',
      rightItems,
      logo,
      sx = [],
      ...restProps
    },
    ref
  ) => {
    return (
      <AppBar
        ref={ref}
        position="absolute"
        sx={[{ boxShadow: 'unset' }, ...(Array.isArray(sx) ? sx : [sx])]}
      >
        <Container maxWidth={maxWidth}>
          <Toolbar style={{ padding: 'unset' }}>
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              alignItems="center"
            >
              {logo}
              <Box
                id="nav-items"
                sx={{
                  display: {
                    xs: 'none',
                    [hideNavItemsOn]: 'block',
                  },
                }}
              >
                {navItems}
              </Box>
              <Box>{rightItems}</Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
);

export default Navbar;
