import { useContext } from 'react';
import { GatsbyContext } from './GatsbyContext';
import { GatsbyContextProps } from './GatsbyInterface';

export const useGatsby = (): GatsbyContextProps => {
  const context = useContext<GatsbyContextProps | undefined>(GatsbyContext);

  if (!context) {
    throw new Error(
      'GatsbyProvider context is undefined, useGatsby needs to be child to <GatsbyProvider>.'
    );
  }

  return context;
};
