import { gql } from '@apollo/client/core';

export const MAPPING_FRAGMENT = gql`
  fragment MappingFragment on Mapping {
    map
    id
    paycode
    description
    standard
    sourceType
  }
`;

export const ADD_UNIQUE_LEAF = gql`
  ${MAPPING_FRAGMENT}
  mutation addUniqueLeaf(
    $groupId: ID!
    $from: LeafMappingInput!
    $to: LeafMappingInput!
  ) {
    addUniqueLeaf(groupId: $groupId, from: $from, to: $to) {
      ...MappingFragment
    }
  }
`;

export const MAPPING_QUERY = gql`
  ${MAPPING_FRAGMENT}
  query mapping($groupId: ID!, $sourceType: String) {
    mapping(groupId: $groupId, sourceType: $sourceType) {
      ...MappingFragment
    }
  }
`;

export const UPDATE_MAPPING = gql`
  ${MAPPING_FRAGMENT}
  mutation updateMapping(
    $groupId: ID!
    $sourceType: String!
    $input: MappingInput!
  ) {
    updateMapping(groupId: $groupId, sourceType: $sourceType, input: $input) {
      ...MappingFragment
    }
  }
`;

export const DELETE_MAPPING = gql`
  mutation deleteMapping($groupId: ID!, $sourceType: String!, $key: String!) {
    deleteMapping(groupId: $groupId, sourceType: $sourceType, key: $key) {
      success
      message
    }
  }
`;

export const SYSTEM_PAYCODES_QUERY = gql`
  query listSystemPaycodes($groupId: ID!) {
    listSystemPaycodes(groupId: $groupId) {
      map
      id
      paycode
      description
      sourceType
    }
  }
`;

export const PAYROLL_QUERY = gql`
  query payslipStats($groupId: ID!) {
    payslipStats(groupId: $groupId) {
      period
      payslipCount
      stats {
        id
        paycode
        average
        count
        max
        median
        min
        total
      }
    }
  }
`;

export const PAYROLL_QUERY_V2 = gql`
  query stats(
    $groupId: ID!
    $type: [StatsType]
    $filter: [SimpleFilter]
    $groupBy: [String]
  ) {
    stats(
      groupId: $groupId
      properties: [AMOUNT, QUANTITY, UNIT_PRICE, INFO]
      type: $type
      filter: $filter
      groupBy: $groupBy
    ) {
      id
      period
      payslipCount
      groupBy
      groupByProp
      paycodes {
        paycode
        source
        map
        description
        properties {
          property
          total
          count
          distinct_count
          median
          max
          min
          average
          averagePerEmployment
        }
      }
    }
  }
`;

export const PAYROLL_QUERY_V2_RANGE = gql`
  query stats($groupId: ID!, $type: [StatsType], $range: Range) {
    stats(
      groupId: $groupId
      properties: [AMOUNT, QUANTITY, UNIT_PRICE, INFO]
      type: $type
      range: $range
    ) {
      id
      period
      payslipCount
      paycodes {
        paycode
        source
        map
        description
        properties {
          property
          total
          count
          distinct_count
          median
          max
          min
          average
          averagePerEmployment
        }
      }
    }
  }
`;

export const PAYROLL_MUTATION_V2_RANGE = gql`
  mutation statsUpdate($groupId: ID!, $type: [StatsType], $range: Range) {
    statsUpdate(
      groupId: $groupId
      properties: [AMOUNT, QUANTITY, UNIT_PRICE, INFO]
      type: $type
      range: $range
    ) {
      id
      period
      payslipCount
      paycodes {
        paycode
        source
        map
        description
        properties {
          property
          total
          count
          distinct_count
          median
          max
          min
          average
          averagePerEmployment
        }
      }
    }
  }
`;

export const payslips = gql`
  query Payslips($groupId: ID!, $lastKey: String, $offset: Int) {
    payslips(groupId: $groupId, lastKey: $lastKey, offset: $offset) {
      lastKey
      result {
        Meta {
          company_registration_number
          employment_nummber
          period
          payment_date
        }
        PayslipTransactions {
          paycode
          from
          to
          quantity
          unit_price
          procent
          amount
          text
        }
      }
    }
  }
`;

export const payslipsByPeriod = gql`
  query payslipsByPeriod(
    $groupId: ID!
    $type: StatsType = PAYSLIP
    $period: String
  ) {
    getPayslipsPerSet(groupId: $groupId, type: $type, period: $period) {
      Meta {
        company_registration_number
        employment_nummber
        period
        payment_date
      }
      PayslipTransactions {
        paycode
        from
        to
        quantity
        unit_price
        procent
        amount
        text
        code
        note
        grouping
      }
    }
  }
`;

export const payslipsPerEmployee = gql`
  query payslipsPerEmployee(
    $groupId: ID!
    $type: StatsType = PAYSLIP
    $employee: EmployeeInput
  ) {
    getPayslips(groupId: $groupId, type: $type, employee: $employee) {
      Meta {
        company_registration_number
        employment_nummber
        period
        payment_date
      }
      PayslipTransactions {
        paycode
        from
        to
        quantity
        unit_price
        procent
        amount
        text
        code
        note
        grouping
      }
      PayslipInfo {
        name
        value_a
        value_n
      }
    }
  }
`;

export const PAYROLL_EMPLOYEE_QUERY = gql`
  query employeeStats(
    $groupId: ID!
    $employee: EmployeeInput
    $type: [StatsType]
    $range: Range
  ) {
    employeeStats(
      groupId: $groupId
      employee: $employee
      properties: [AMOUNT, QUANTITY, UNIT_PRICE, INFO]
      type: $type
      range: $range
    ) {
      period
      payslipCount
      paycodes {
        paycode
        source
        map
        description
        properties {
          property
          total
          count
          distinct_count
          median
          max
          min
          average
          averagePerEmployment
        }
      }
    }
  }
`;

export const PERIODS = gql`
  query periods($groupId: ID!) {
    periods(groupId: $groupId) {
      period
    }
  }
`;

export const PAYROLL_STATS_DETAILS = gql`
  query details(
    $groupId: ID!
    $period: String!
    $paycodes: [String]
    $type: StatsType
  ) {
    details(
      groupId: $groupId
      period: $period
      paycodes: $paycodes
      type: $type
    ) {
      type
      paycode
      from
      to
      quantity
      unit_price
      procent
      amount
      text
      employee {
        company_registration_number
        employment_nummber
      }
      note
      costCenter
      project
      resultUnit
      account
    }
  }
`;
